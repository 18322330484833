// DashboardIndicatorsStyle.js

import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const DashboardContainer = styled('div')(({ isSidebarOpen }) => ({
    position: 'fixed',
    top: 0,
    left: isSidebarOpen ? '75px' : '60px', // Ajusta según el ancho del Sidebar cuando está abierto o cerrado
    width: `calc(100% - ${isSidebarOpen ? '75px' : '60px'})`, // Ancho restante
    height: '100vh', // Altura completa del viewport
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    transition: 'left 0.3s, width 0.3s',
}));

export const ContentContainer = styled('div')(() => ({
    flex: 1, // Ocupa todo el espacio disponible
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    paddingTop: '75px'
}));

export const RightPanel = styled('div')(() => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
}));

export const CardsContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '16px',
    flex: 1,
    paddingLeft: '45px'
}));

export const ChartCardContainer = styled('div')(() => ({
    flex: '1 1 300px', // Flex-grow, flex-shrink, flex-basis
    maxWidth: '33.333%', // Tres tarjetas por fila
    minWidth: '250px', // Ancho mínimo para evitar desbordamientos
    margin: '10px',
    boxSizing: 'border-box',
    height: 'auto',
    overflow: 'visible',
    '@media (max-width: 1200px)': {
        maxWidth: '50%', // Dos tarjetas por fila en pantallas medianas
    },
    '@media (max-width: 768px)': {
        maxWidth: '100%', // Una tarjeta por fila en pantallas pequeñas
    },
}));

export const InProgressMessage = styled('div')(() => ({
    color: '#B0B0B0', // Gris claro
    textAlign: 'center',
    marginTop: '100px',
    fontSize: '24px',
    fontWeight: '600',
    padding: '20px',
}));

// Nueva exportación para TooltipContainer
export const TooltipContainer = styled('div')(({ top, left }) => ({
    position: 'absolute',
    backgroundColor: '#333',
    color: '#fff',
    padding: '5px 10px',
    borderRadius: '5px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
    whiteSpace: 'nowrap',
    zIndex: 1200,
    fontSize: '0.9em',
    pointerEvents: 'none',
    top: top,
    left: left,
}));

// Nueva exportación para ModalContent
export const ModalContent = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '300px',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    zIndex: 1300, // Asegura que esté por encima de otros elementos
    animation: 'fadeIn 0.5s ease-in-out',
    '@keyframes fadeIn': {
        from: {
            opacity: 0,
            transform: 'translate(-50%, -60%)',
        },
        to: {
            opacity: 1,
            transform: 'translate(-50%, -50%)',
        },
    },
}));
