import { styled } from '@mui/system';
import { Button } from '@mui/material'; // Asegúrate de importar Button desde Material UI

export const DashboardContainer = styled('div')({
    display: 'flex',
    marginLeft: '60px',
});

export const MainContent = styled('main')(({ isSidebarOpen }) => ({
    marginLeft: '0px',
    width: `100%`,
}));


export const DashboardControls = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
});

export const MapSectionContainer = styled('div')({
    marginTop: '205px',
});

export const AnalysisSection = styled('div')({
    marginTop: '20px',
});

