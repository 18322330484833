import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSocket } from '../../context/SocketContext';
import { API_BASE_PYTHON_SERVICE } from '../../utils/config';
import axios from 'axios';
import {
    polygon as turfPolygon,
    area as turfArea,
    union as turfUnion,
    difference as turfDifference,
    intersect as turfIntersect,
    buffer as turfBuffer,
    lineSplit as turfLineSplit,
    lineString as turfLineString,
    bbox as turfBbox,
    lineIntersect as turfLineIntersect,
    length as turfLength,
    nearestPointOnLine as turfNearestPointOnLine,
    pointToLineDistance as turfPointToLineDistance,
    distance as turfDistance,
    point as turfPoint,
    destination as turfDestination,
    bearing as turfBearing,
} from '@turf/turf';
import BarIndicator from '../../components/BarIndicator/BarIndicator';
import { v4 as uuidv4 } from 'uuid';
import CommonMap from '../../components/CommonMap/CommonMap';
import MapDialog from '../../components/MapDialog/MapDialog';
import FloatingToolsAerialApplications from '../../components/FloatingToolsAerialApplications/FloatingToolsAerialApplications';
import { Snackbar, Alert } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const AerialApplications = ({
                                idAnalisis,
                                tipoAnalisis,
                                onAreasCalculated,
                                onPromediosCalculated,
                                activarEdicionInteractiva,
                                highlightedLote,
                                activeLotes,
                                polygonsData,
                                onSelectLote,
                                onLeaveLote,
                                onHoverLote,
                                isFilterDialogOpen,
                                closeFilterDialog,
                                setImgLaflet,
                                mapRef,
                                polygonsLayerRef,
                                setSelectedIndicator,
                                setLabelsAndColors,
                                setOnAreasCalculatedLoader,
                                setTipoMapeo
                            }) => {
    const userData = JSON.parse(localStorage.getItem('userData'));

    const [polygons, setPolygons] = useState([]);
    const [areasSuperpuestas, setAreasSuperpuestas] = useState([]);
    const [mapCenter, setMapCenter] = useState([0, 0]);
    const [zoom, setZoom] = useState(3);
    const [activeFilter, setActiveFilter] = useState(null);
    const [speedFilterActivated, setSpeedFilterActivated] = useState(false);
    const [altitudeFilterActivated, setAltitudeFilterActivated] = useState(false);
    const [realDoseFilterActivated, setRealDoseFilterActivated] = useState(false);
    const [isMapCreated, setIsMapCreated] = useState(new Date());
    const [nonIntersectedAreas, setNonIntersectedAreas] = useState([]);
    const [polygonsProperties, setPolygonsProperties] = useState([]);
    const [intersectionsKey, setIntersectionsKey] = useState(Date.now());
    const [showIntersections, setShowIntersections] = useState(true);
    const [overAppliedArea, setOverAppliedArea] = useState(0);
    const [appliedArea, setAppliedArea] = useState(0);
    const [lines, setLines] = useState([]);
    const [lineasNoFiltradas, setLineasNoFiltradas] = useState([]);
    const [onClickLinea, setOnClickLinea] = useState(false);
    const [onClickCuteLine, setOnClickCuteLine] = useState(false);
    const [onClickDrawLine, setOnClickDrawLine] = useState(false);
    const [onClickLineaStrech, setOnClickLineaStrech] = useState(false);

    const [bufferedLines, setBufferedLines] = useState([]);
    const [formData, setFormData] = useState({ idAnalisis });
    const [bufferedIntersections, setBufferedIntersections] = useState([]);
    const [kmlPolygons, setKmlPolygons] = useState([]);
    const [isKml, setIsKml] = useState(false);
    const [isDrawingLine, setIsDrawingLine] = useState(false);
    const [activeTool, setActiveTool] = useState(null);
    const [selectedLine, setSelectedLine] = useState(null);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isFilteringLines, setIsFilteringLines] = useState(true);
    const [actionHistory, setActionHistory] = useState([]);
    const DISTANCE_THRESHOLD = 0.005;
    const ANGLE_THRESHOLD = 1;
    const [bufferValue, setBufferValue] = useState(0);
    const [isBufferActive, setIsBufferActive] = useState(false);
    const [stretchPoints, setStretchPoints] = useState([]);
    const [showUnfilteredLines, setShowUnfilteredLines] = useState(true);

    const workerRef = useRef(null);
    const activeToolRef = useRef(activeTool);

    const [filterValues, setFilterValues] = useState({
        VELOCIDAD: { low: 0, medium: 0, high: 0 },
        ALTURA: { low: 0, medium: 0, high: 0 },
        DOSISREAL: { low: 0, medium: 0, high: 0 },
    });

    const [availableFilters, setAvailableFilters] = useState({
        speed: false,
        altitude: false,
        realDose: false,
    });

    const [filterSpeed, setFilterSpeed] = useState(false);
    const [filterAltitude, setFilterAltitude] = useState(false);
    const [filterRealDose, setFilterRealDose] = useState(false);

    const [lowSpeed, setLowSpeed] = useState(0);
    const [medSpeed, setMedSpeed] = useState(0);
    const [highSpeed, setHighSpeed] = useState(0);

    const [lowAltitude, setLowAltitude] = useState(0);
    const [medAltitude, setMedAltitude] = useState(0);
    const [highAltitude, setHighAltitude] = useState(0);

    const [lowRealDose, setLowRealDose] = useState(0);
    const [medRealDose, setMedRealDose] = useState(0);
    const [highRealDose, setHighRealDose] = useState(0);
    const timeoutRef = useRef(null);

    const socketContext = useSocket();
    const { socket, socketSessionID } = socketContext;
    const currentToolCleanupRef = useRef(null);

    const [areasCalculated, setAreasCalculated] = useState(false);
    const [popupInfo, setPopupInfo] = useState(null);

    const nextLineIdRef = useRef(0); // Usamos useRef para mantener el ID entre renderizados

    const handleToggleFilter = useCallback((filterName) => {
        switch (filterName) {
            case 'VELOCIDAD':
                setFilterSpeed((prev) => !prev);
                setActiveFilter('speed');
                break;
            case 'ALTURA':
                setFilterAltitude((prev) => !prev);
                setActiveFilter('altura');
                break;
            case 'DOSISREAL':
                setFilterRealDose((prev) => !prev);
                setActiveFilter('dosisReal');
                break;
            default:
                break;
        }
    }, []);

    const deactivateTool = useCallback(() => {
        setActiveTool(null);

        if (mapRef.current) {
            const map = mapRef.current;

            // Remove any event listeners added by tools
            if (currentToolCleanupRef.current) {
                currentToolCleanupRef.current();
                currentToolCleanupRef.current = null;
            }

            // Remove any temporary layers
            if (map.getLayer('drawing-line-layer')) {
                map.removeLayer('drawing-line-layer');
            }
            if (map.getSource('drawing-line')) {
                map.removeSource('drawing-line');
            }
            if (map.getLayer('cutting-line-layer')) {
                map.removeLayer('cutting-line-layer');
            }
            if (map.getSource('cutting-line')) {
                map.removeSource('cutting-line');
            }
        }
    }, [mapRef]);

    const autoPanMap = useCallback((e) => {
        const map = mapRef.current;
        if (!map) return;

        const canvas = map.getCanvas();
        const rect = canvas.getBoundingClientRect();
        const edgeThreshold = 50;

        const mouseX = e.point.x;
        const mouseY = e.point.y;

        const panOffset = { x: 0, y: 0 };

        if (mouseX < edgeThreshold) {
            panOffset.x = -50;
        } else if (mouseX > rect.width - edgeThreshold) {
            panOffset.x = 50;
        }

        if (mouseY < edgeThreshold) {
            panOffset.y = -50;
        } else if (mouseY > rect.height - edgeThreshold) {
            panOffset.y = 50;
        }

        if (panOffset.x !== 0 || panOffset.y !== 0) {
            map.panBy([panOffset.x, panOffset.y], { duration: 100 });
        }
    }, [mapRef]);

    useEffect(() => {
        applyFilters();
    }, [
        filterSpeed,
        filterAltitude,
        filterRealDose,
        lowSpeed,
        medSpeed,
        highSpeed,
        lowAltitude,
        medAltitude,
        highAltitude,
        lowRealDose,
        medRealDose,
        highRealDose,
    ]);

    const applyFilters = useCallback(() => {
        setPolygonsProperties((prevProps) =>
            prevProps.map((prop) => {
                let color = 'green';
                if (filterSpeed) {
                    const speed = prop.VELOCIDAD;
                    if (speed < lowSpeed) {
                        color = 'green';
                    } else if (speed >= lowSpeed && speed < medSpeed) {
                        color = 'yellow';
                    } else if (speed >= medSpeed && speed <= highSpeed) {
                        color = 'orange';
                    } else {
                        color = 'red';
                    }
                }
                if (filterAltitude) {
                    const altitude = prop.ALTURA;
                    if (altitude < lowAltitude) {
                        color = 'green';
                    } else if (altitude >= lowAltitude && altitude < medAltitude) {
                        color = 'yellow';
                    } else if (altitude >= medAltitude && altitude <= highAltitude) {
                        color = 'orange';
                    } else {
                        color = 'red';
                    }
                }
                if (filterRealDose) {
                    const realDose = prop.DOSISREAL;
                    if (realDose < lowRealDose) {
                        color = 'green';
                    } else if (realDose >= lowRealDose && realDose < medRealDose) {
                        color = 'yellow';
                    } else if (realDose >= medRealDose && realDose <= highRealDose) {
                        color = 'orange';
                    } else {
                        color = 'red';
                    }
                }
                return { ...prop, color };
            })
        );
    }, [
        filterSpeed,
        filterAltitude,
        filterRealDose,
        lowSpeed,
        medSpeed,
        highSpeed,
        lowAltitude,
        medAltitude,
        highAltitude,
        lowRealDose,
        medRealDose,
        highRealDose,
    ]);

    useEffect(() => {
        workerRef.current = new Worker('Workers/dataWorker.js');

        workerRef.current.onmessage = (e) => {
            if (e.data.action === 'geoJsonDataProcessed') {
                const createLatLngArray = (paths) => {
                    let latLngArray = [];

                    const processCoordinates = (coords) => {
                        if (Array.isArray(coords[0])) {
                            coords.forEach((coord) => {
                                processCoordinates(coord);
                            });
                        } else {
                            if (coords.length !== 2) {
                                console.error('Coordenada inválida:', coords);
                                return;
                            }
                            const [lat, lng] = coords;
                            latLngArray.push({ lat, lng });
                        }
                    };

                    processCoordinates(paths);

                    return latLngArray;
                };

                if (e.data.data.filtradas && e.data.data.filtradas.lines) {
                    const { lines: filtradasLines, polygons: filtradasPolygons } = e.data.data.filtradas;
                    const lineIds = [];
                    const filtradasLinesWithEvents = filtradasLines
                        .map((line) => {
                            let lineId = parseInt(line.id, 10);
                            if (isNaN(lineId)) {
                                lineId = nextLineIdRef.current++;
                            }
                            lineIds.push(lineId);
                            const latLngArray = createLatLngArray(line.paths);

                            if (latLngArray.length < 2) {
                                console.error('Segmento con menos de 2 puntos:', latLngArray);
                                return null;
                            }

                            const lineObj = { id: lineId, latlngs: latLngArray, properties: line.properties };
                            return lineObj;
                        })
                        .filter((line) => line !== null);

                    // Actualizamos nextLineIdRef si es necesario
                    const maxLineId = Math.max(...lineIds);
                    if (nextLineIdRef.current <= maxLineId) {
                        nextLineIdRef.current = maxLineId + 1;
                    }

                    setLines(filtradasLinesWithEvents);
                    setIsKml(true);
                    setKmlPolygons(filtradasPolygons);
                    setIsFilteringLines(true);
                }

                if (e.data.data.noFiltradas && e.data.data.noFiltradas.lines) {
                    const { lines: noFiltradasLines } = e.data.data.noFiltradas;
                    const lineIds = [];
                    const noFiltradasLinesWithEvents = noFiltradasLines
                        .map((line) => {
                            let lineId = parseInt(line.id, 10);
                            if (isNaN(lineId)) {
                                lineId = nextLineIdRef.current++;
                            }
                            lineIds.push(lineId);
                            const latLngArray = createLatLngArray(line.paths);

                            if (latLngArray.length < 2) {
                                console.error('Segmento con menos de 2 puntos:', latLngArray);
                                return null;
                            }

                            const lineObj = { id: lineId, latlngs: latLngArray };
                            return lineObj;
                        })
                        .filter((line) => line !== null);

                    // Actualizamos nextLineIdRef si es necesario
                    const maxLineId = Math.max(...lineIds);
                    if (nextLineIdRef.current <= maxLineId) {
                        nextLineIdRef.current = maxLineId + 1;
                    }

                    setLineasNoFiltradas(noFiltradasLinesWithEvents);
                }

                if (e.data.data.lines && !e.data.data.noFiltradas) {
                    const { lines, polygons } = e.data.data;
                    const lineIds = [];
                    const linesWithEvents = lines
                        .map((line) => {
                            let lineId = parseInt(line.id, 10);
                            if (isNaN(lineId)) {
                                lineId = nextLineIdRef.current++;
                            }
                            lineIds.push(lineId);
                            const latLngArray = createLatLngArray(line.paths);

                            if (latLngArray.length < 2) {
                                console.error('Segmento con menos de 2 puntos:', latLngArray);
                                return null;
                            }

                            const lineObj = { id: lineId, latlngs: latLngArray, properties: line.properties };
                            return lineObj;
                        })
                        .filter((line) => line !== null);

                    // Actualizamos nextLineIdRef si es necesario
                    const maxLineId = Math.max(...lineIds);
                    if (nextLineIdRef.current <= maxLineId) {
                        nextLineIdRef.current = maxLineId + 1;
                    }

                    setLines(linesWithEvents);
                    setIsKml(true);
                    setKmlPolygons(polygons);
                    setIsFilteringLines(false);
                }

                if (!e.data.data.lines && e.data.data.polygons) {
                    const { polygons } = e.data.data;
                    const formattedPolygons = polygons.map((poly) => formatPolygon(poly.polygon[0]));
                    setPolygonsProperties(polygons.map((poly) => poly.properties));
                    setPolygons(formattedPolygons);
                    setIsFilteringLines(false);
                }
            }
        };

        if (socket) {
            socket.on(`${socketSessionID}:updateGeoJSONLayer`, (geojsonData) => {
                workerRef.current.postMessage({
                    action: 'processGeoJsonData',
                    geojsonData,
                    type: tipoAnalisis,
                    activarEdicionInteractiva,
                });
            });
        }

        return () => {
            if (workerRef.current) {
                workerRef.current.terminate();
            }
            if (socket) {
                socket.off(`${socketSessionID}:updateGeoJSONLayer`);
            }
        };
    }, [activarEdicionInteractiva, socket, socketSessionID, tipoAnalisis]);

    useEffect(() => {
        if (workerRef.current) {
            workerRef.current.postMessage({ action: 'setActivarEdicionInteractiva', activarEdicionInteractiva });
        }
    }, [activarEdicionInteractiva]);

    const addBufferToLine = (line, width) => {
        try {
            const coordinates = line.latlngs.map((coord) => [coord.lng, coord.lat]);

            const lineString = {
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: coordinates,
                },
                properties: line.properties || {},
            };

            const bufferWidth = width > 0 ? width : 1;

            const bufferedLine = turfBuffer(lineString, bufferWidth, { units: 'meters' });

            if (
                !bufferedLine ||
                !bufferedLine.geometry ||
                !Array.isArray(bufferedLine.geometry.coordinates) ||
                bufferedLine.geometry.coordinates.length === 0
            ) {
                throw new Error('Buffer result is invalid.');
            }

            return bufferedLine;
        } catch (error) {
            console.error('Error in addBufferToLine:', error);
            return null;
        }
    };

    useEffect(() => {
        if (isBufferActive && lines) {
            const newBufferedLines = lines
                .map((line) => {
                    if (line.latlngs && Array.isArray(line.latlngs) && line.latlngs.length > 0) {
                        const bufferedLine = addBufferToLine(line, parseFloat(bufferValue));
                        if (bufferedLine) {
                            return bufferedLine;
                        } else {
                            console.warn('Resultado de buffer inválido para la línea: ', line);
                            return null;
                        }
                    }
                    console.error('Datos de línea inválidos: falta o incorrecto latlngs');
                    return null;
                })
                .filter((bufferedLine) => bufferedLine !== null);
            setBufferedLines(newBufferedLines);
            setAreasCalculated(false);
        } else {
            setBufferedLines([]);
        }
    }, [bufferValue, lines, isBufferActive]);

    const handleToggleBuffer = () => {
        try {
            if (isBufferActive) {
                setBufferedLines([]);
                setBufferedIntersections([]);
                setAreasCalculated(false);
            } else {
                const newBufferedLines = lines
                    .map((line) => {
                        if (line.latlngs && Array.isArray(line.latlngs) && line.latlngs.length > 0) {
                            const bufferedLine = addBufferToLine(line, parseFloat(bufferValue));
                            if (bufferedLine) {
                                return bufferedLine;
                            } else {
                                console.warn('Resultado de buffer inválido para la línea: ', line);
                                return null;
                            }
                        }
                        console.error('Datos de línea inválidos: falta o incorrecto latlngs');
                        return null;
                    })
                    .filter((bufferedLine) => bufferedLine !== null);
                setBufferedLines(newBufferedLines);
                setAreasCalculated(false);
            }
            setIsBufferActive(!isBufferActive);
        } catch (error) {
            console.error('Error al aplicar buffer:', error);
        }
    };

    useEffect(() => {
        if (mapRef.current && polygons.length > 0) {
            const latLngCoords = polygons.flatMap((polygon) =>
                polygon.map((coordPair) => [coordPair[0], coordPair[1]])
            );
            if (latLngCoords.length > 0 || activeFilter) {
                setIntersectionsKey(Date.now());
                findIntersections(polygons);
            }
        }
    }, [polygons, lines, activeFilter]);

    useEffect(() => {
        const checkAvailableFilters = () => {
            const hasSpeed = polygonsProperties.some((prop) => prop.VELOCIDAD != null && prop.VELOCIDAD !== '');
            const hasAltitude = polygonsProperties.some((prop) => prop.ALTURA != null && prop.ALTURA !== '');
            const hasRealDose = polygonsProperties.some((prop) => prop.DOSISREAL != null && prop.DOSISREAL !== '');

            setAvailableFilters({
                speed: hasSpeed,
                altitude: hasAltitude,
                realDose: hasRealDose,
            });
        };

        checkAvailableFilters();
    }, [polygonsProperties]);

    const handleLineHover = useCallback((feature) => {
        // No es necesario manejar el estado del hover aquí.
    }, []);

    const handleLineMouseOut = useCallback(() => {
        // No es necesario manejar el estado del hover aquí.
    }, []);

    const handleLineClick = useCallback(
        (e) => {
            const feature = e.features[0];
            const lngLat = e.lngLat;

            const properties = feature.properties;
            if (activeToolRef.current === 'delete') {
                const lineInState = lines.find((l) => l.id === feature.id);
                if (lineInState) {
                    setLines((prevLines) => prevLines.filter((l) => l.id !== lineInState.id));
                    setActionHistory((prevHistory) => [...prevHistory, { type: 'delete', line: lineInState }]);
                } else {
                    console.warn('No se encontró la línea en el estado para eliminar.');
                }
            } else {
                const coordinates = feature.geometry.coordinates;
                const lineString = turfLineString(coordinates);
                const lengthKm = turfLength(lineString, { units: 'kilometers' });
                const lengthMiles = turfLength(lineString, { units: 'miles' });
                const lengthMeters = lengthKm * 1000;

                const propertyList =
                    properties && Object.keys(properties).length > 0
                        ? Object.entries(properties).map(([key, value]) => (
                            <li key={key}><strong>{key}:</strong> {value !== undefined && value !== null ? value : 'N/A'}</li>
                        ))
                        : null;

                const popupContent = (
                    <div>
                        {propertyList && (
                            <div>
                                <strong>Información de aplicación:</strong>
                                <ul>
                                    {propertyList}
                                </ul>
                            </div>
                        )}
                        <div>
                            <strong>Tamaño línea aplicación:</strong>
                            <ul>
                                <li>{lengthKm.toFixed(3)} km</li>
                                <li>{lengthMiles.toFixed(3)} mi</li>
                                <li>{lengthMeters.toFixed(3)} m</li>
                            </ul>
                        </div>
                    </div>
                );

                setPopupInfo({ lngLat, content: popupContent });
            }
        },
        [lines]
    );

    useEffect(() => {
        Promise.resolve(idAnalisis)
            .then((resolvedId) => {
                if (resolvedId && resolvedId.data) {
                    const actualId = resolvedId.data.ID_ANALISIS;
                    setFormData((currentData) => ({
                        ...currentData,
                        idAnalisis: actualId,
                    }));
                } else {
                    console.error("resolvedId es null o no tiene la propiedad 'data'");
                }
            })
            .catch((error) => {
                console.error('Error al resolver idAnalisis:', error);
            });
    }, [idAnalisis]);

    const formatPolygon = useCallback((polygon) => {
        if (polygon.length >= 3) {
            if (
                polygon[0][0] !== polygon[polygon.length - 1][0] ||
                polygon[0][1] !== polygon[polygon.length - 1][1]
            ) {
                polygon.push([polygon[0][0], polygon[0][1]]);
            }
            return polygon.map((coordPair) => [coordPair[0], coordPair[1]]);
        }
        return [];
    }, []);

    const findIntersections = (polygons) => {
        let intersections = [];
        polygons.forEach((poly1, i) => {
            polygons.slice(i + 1).forEach((poly2) => {
                if (poly1.length > 0 && poly2.length > 0) {
                    const intersection = turfIntersect(turfPolygon([poly1]), turfPolygon([poly2]));
                    if (intersection) {
                        if (intersection.geometry.type === 'MultiPolygon') {
                            intersection.geometry.coordinates.forEach((coords) => {
                                intersections.push(coords[0]);
                            });
                        } else if (intersection.geometry.type === 'Polygon') {
                            intersections.push(intersection.geometry.coordinates[0]);
                        }
                    }
                }
            });
        });
        setAreasSuperpuestas(intersections);
    };

    const calculateNonIntersectedAreas = (polygons) => {
        let nonIntersectedAreas = [];

        if (polygons.length > 1) {
            const unionOfAllPolygons = polygons.slice(1).reduce((acc, polygon) => {
                return turfUnion(acc, turfPolygon([polygon]));
            }, turfPolygon([polygons[0]]));

            polygons.forEach((polygon) => {
                const difference = turfDifference(turfPolygon([polygon]), unionOfAllPolygons);
                if (difference) {
                    nonIntersectedAreas.push(difference.geometry.coordinates);
                }
            });
        } else {
            nonIntersectedAreas.push(polygons[0]);
        }

        return nonIntersectedAreas;
    };

    useEffect(() => {
        if (polygons.length > 0) {
            const newNonIntersectedAreas = calculateNonIntersectedAreas(polygons);
            setNonIntersectedAreas(newNonIntersectedAreas);
        }
    }, [polygons]);

    const correctionFactor = 1.014;
    const correctionFactorIntersections = 1.98;

    useEffect(() => {
        if (polygons.length === 0 || polygonsData.length === 0) return;

        const calculateAreas = async () => {
            try {
                const requestData = {
                    bufferedLines: [],
                    polygons: polygons.map((polygon) => ({
                        geometry: {
                            type: 'Polygon',
                            coordinates: [
                                polygon.map(([lat, lon]) => [lon, lat]),
                            ],
                        },
                    })),
                    polygonsData: polygonsData.map((polygonData) => ({
                        type: polygonData.type,
                        geometry: polygonData.geometry,
                        properties: polygonData.properties,
                    })),
                    id_analisis: idAnalisis.data.ID_ANALISIS,
                };

                const response = await axios.post(`${API_BASE_PYTHON_SERVICE}mapping/calculate_areas`, requestData);
                setOnAreasCalculatedLoader(true);

                if (response.status === 200 && response.data) {
                    const {
                        areaSobreAplicada,
                        areaAplicada,
                        areaNoAplicada,
                        porcentajeDeVariacion,
                        areaAplicadaEnCalles,
                        areaAplicadaFueraDelLote,
                        dosisTotalDiferencia,
                        dosisTotalRealAplicada,
                    } = response.data.data;

                    if (onAreasCalculated) {
                        onAreasCalculated({
                            areaSobreAplicada,
                            areaAplicada,
                            areaNoAplicada,
                            porcentajeDeVariacion,
                            areaAplicadaEnCalles,
                            areaAplicadaFueraDelLote,
                            dosisTotalRealAplicada,
                            dosisTotalDiferencia,
                        });
                    }
                } else {
                    console.error('Error al calcular áreas: Respuesta no válida del servidor');
                }
            } catch (error) {
                console.error('Error al calcular áreas:', error);
            }
        };

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            calculateAreas();
        }, 500);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [polygons, polygonsData, idAnalisis]);

    useEffect(() => {
        if (polygons.length === 0 || polygonsProperties.length === 0) return;
        const totalSpeed = polygonsProperties.reduce((acc, curr) => acc + (curr.VELOCIDAD || 0), 0);
        const totalAltitude = polygonsProperties.reduce((acc, curr) => acc + (curr.ALTURA || 0), 0);
        const totalRealDose = polygonsProperties.reduce((acc, curr) => acc + (curr.DOSISREAL || 0), 0);

        const promedioVelocidad = (totalSpeed / polygonsProperties.length).toFixed(3);
        const promedioAltura = (totalAltitude / polygonsProperties.length).toFixed(3);
        const promedioDosisReal = (totalRealDose / polygonsProperties.length).toFixed(3);

        if (onPromediosCalculated) {
            onPromediosCalculated({
                promedioVelocidad,
                promedioAltura,
                promedioDosisReal,
            });
        }
    }, [polygons, polygonsProperties]);

    useEffect(() => {
        if (bufferedLines.length === 0 || polygonsData.length === 0) return;

        const calculateBufferedIntersections = (bufferedLines) => {
            let intersections = [];

            bufferedLines.forEach((buffer1, i) => {
                bufferedLines.slice(i + 1).forEach((buffer2) => {
                    try {
                        const intersection = turfIntersect(buffer1, buffer2);
                        if (intersection) {
                            const coords = intersection.geometry.coordinates;
                            if (intersection.geometry.type === 'MultiPolygon') {
                                coords.forEach((coord) => intersections.push(coord[0]));
                            } else {
                                intersections.push(coords[0]);
                            }
                        }
                    } catch (error) {
                        // Manejar error si es necesario
                    }
                });
            });
            setBufferedIntersections(intersections);

            const totalIntersectionArea = intersections.reduce(
                (acc, intersection) => acc + turfArea(turfPolygon([intersection])) / 10000,
                0
            );

            return totalIntersectionArea;
        };

        calculateBufferedIntersections(bufferedLines);

        const calculateAreas = async () => {
            try {
                const requestData = {
                    bufferedLines: bufferedLines.map((line) => ({
                        geometry: line.geometry,
                    })),
                    polygons: [],
                    polygonsData: polygonsData.map((polygonData) => ({
                        type: polygonData.type,
                        geometry: polygonData.geometry,
                        properties: polygonData.properties,
                    })),
                    id_analisis: idAnalisis.data.ID_ANALISIS,
                };

                const response = await axios.post(`${API_BASE_PYTHON_SERVICE}mapping/calculate_areas`, requestData);
                setOnAreasCalculatedLoader(true);

                if (response.status === 200 && response.data) {
                    const {
                        areaSobreAplicada,
                        areaAplicada,
                        areaNoAplicada,
                        porcentajeDeVariacion,
                        areaAplicadaEnCalles,
                        areaAplicadaFueraDelLote,
                        dosisTotalDiferencia,
                        dosisTotalRealAplicada,
                    } = response.data.data;

                    if (onAreasCalculated) {
                        onAreasCalculated({
                            areaSobreAplicada,
                            areaAplicada,
                            areaNoAplicada,
                            porcentajeDeVariacion,
                            areaAplicadaEnCalles,
                            areaAplicadaFueraDelLote,
                            dosisTotalRealAplicada,
                            dosisTotalDiferencia,
                        });
                    }

                    setAreasCalculated(true);
                } else {
                    console.error('Error al calcular áreas: Respuesta no válida del servidor');
                }
            } catch (error) {
                console.error('Error al calcular áreas:', error);
            }
        };

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            setOnAreasCalculatedLoader(false);
            calculateAreas();
        }, 2000);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [bufferedLines, polygonsData, idAnalisis]);

    const handleDrawLine = useCallback(() => {
        if (activeTool === 'draw') {
            deactivateTool();
            setActiveTool(null);
        } else {
            deactivateTool();
            setActiveTool('draw');
            setIsFirstLoad(false);

            if (mapRef.current) {
                const map = mapRef.current;
                let newLineCoords = [];
                let isDrawing = false;

                const onMapClick = (e) => {
                    const coords = [e.lngLat.lng, e.lngLat.lat];
                    newLineCoords.push(coords);

                    if (!isDrawing) {
                        isDrawing = true;
                        map.on('mousemove', onMouseMove);
                    }

                    updateDrawingLayer(newLineCoords);
                };

                const onMouseMove = (e) => {
                    if (isDrawing) {
                        const coords = [e.lngLat.lng, e.lngLat.lat];
                        const currentLine = [...newLineCoords, coords];
                        updateDrawingLayer(currentLine);
                        autoPanMap(e);
                    }
                };

                const onRightClick = (e) => {
                    e.preventDefault();
                    if (newLineCoords.length < 2) {
                        console.error('La línea debe tener al menos dos puntos');
                        resetDrawing();
                        return;
                    }

                    const lineObj = {
                        id: nextLineIdRef.current++,
                        latlngs: newLineCoords.map((coord) => ({ lat: coord[1], lng: coord[0] })),
                        properties: {},
                    };

                    setLines((prevLines) => [...prevLines, lineObj]);
                    setActionHistory((prevHistory) => [...prevHistory, { type: 'draw', line: lineObj }]);

                    resetDrawing();
                    setOnClickDrawLine(true);
                };

                const resetDrawing = () => {
                    if (mapRef.current) {
                        const map = mapRef.current;
                        isDrawing = false;
                        newLineCoords = [];
                        map.off('mousemove', onMouseMove);
                        map.off('click', onMapClick);
                        map.off('contextmenu', onRightClick);
                        removeDrawingLayer();

                        // Reiniciar el proceso de dibujo si la herramienta sigue activa
                        if (activeToolRef.current === 'draw') {
                            map.on('click', onMapClick);
                            map.on('contextmenu', onRightClick);
                        }
                    }
                };

                const updateDrawingLayer = (coordinates) => {
                    if (mapRef.current) {
                        const map = mapRef.current;
                        const source = map.getSource('drawing-line');
                        if (source) {
                            source.setData({
                                type: 'Feature',
                                geometry: {
                                    type: 'LineString',
                                    coordinates: coordinates,
                                },
                            });
                        } else {
                            map.addSource('drawing-line', {
                                type: 'geojson',
                                data: {
                                    type: 'Feature',
                                    geometry: {
                                        type: 'LineString',
                                        coordinates: coordinates,
                                    },
                                },
                            });
                            map.addLayer({
                                id: 'drawing-line-layer',
                                type: 'line',
                                source: 'drawing-line',
                                paint: {
                                    'line-color': '#ff0000',
                                    'line-width': 2,
                                },
                            });
                        }
                    }
                };

                const removeDrawingLayer = () => {
                    if (mapRef.current) {
                        const map = mapRef.current;
                        if (map.getLayer('drawing-line-layer')) {
                            map.removeLayer('drawing-line-layer');
                        }
                        if (map.getSource('drawing-line')) {
                            map.removeSource('drawing-line');
                        }
                    }
                };

                map.on('click', onMapClick);
                map.on('contextmenu', onRightClick);

                currentToolCleanupRef.current = () => {
                    map.off('click', onMapClick);
                    map.off('mousemove', onMouseMove);
                    map.off('contextmenu', onRightClick);
                };
            } else {
                console.error('Map not ready or reference is null');
            }
        }
    }, [activeTool, deactivateTool, autoPanMap, setLines, setActionHistory]);

    const handleCutLine = useCallback(() => {
        if (activeTool === 'cut') {
            deactivateTool();
            setActiveTool(null);
        } else {
            setIsFirstLoad(false);
            deactivateTool();
            setActiveTool('cut');

            if (mapRef.current && lines.length > 0) {
                const map = mapRef.current;
                let isCutting = false;
                let cutLineCoords = [];

                const onMapClick = (e) => {
                    const coords = [e.lngLat.lng, e.lngLat.lat];

                    if (!isCutting) {
                        isCutting = true;
                        cutLineCoords.push(coords);
                        map.on('mousemove', onMouseMove);
                    } else {
                        cutLineCoords.push(coords);
                        performCut();
                        resetCutting();
                    }

                    updateCuttingLayer(cutLineCoords);
                };

                const onMouseMove = (e) => {
                    if (isCutting) {
                        const coords = [e.lngLat.lng, e.lngLat.lat];
                        const currentLine = [...cutLineCoords, coords];
                        updateCuttingLayer(currentLine);
                        autoPanMap(e);
                    }
                };

                const performCut = () => {
                    if (cutLineCoords.length < 2) {
                        console.error('La línea de corte debe tener al menos dos puntos');
                        return;
                    }

                    const cutLineString = turfLineString(cutLineCoords);

                    const newLines = [];
                    let cutSuccessful = false;
                    const originalLines = [...lines];

                    lines.forEach((line) => {
                        const latlngs = line.latlngs;
                        if (!latlngs || latlngs.length < 2) {
                            newLines.push(line);
                            return;
                        }

                        const lineString = turfLineString(latlngs.map((coord) => [coord.lng, coord.lat]));
                        const intersections = turfLineIntersect(lineString, cutLineString);

                        if (intersections.features.length > 0) {
                            cutSuccessful = true;
                            const splitResult = turfLineSplit(lineString, cutLineString);

                            splitResult.features.forEach((f) => {
                                const newLineCoords = f.geometry.coordinates.map((coord) => ({
                                    lat: coord[1],
                                    lng: coord[0],
                                }));
                                const lineObj = {
                                    id: nextLineIdRef.current++,
                                    latlngs: newLineCoords,
                                    properties: line.properties,
                                };
                                newLines.push(lineObj);
                            });
                        } else {
                            newLines.push(line);
                        }
                    });

                    if (cutSuccessful) {
                        setLines(newLines);
                        setActionHistory((prevHistory) => [...prevHistory, { type: 'cut', originalLines }]);
                    }
                };

                const resetCutting = () => {
                    if (mapRef.current) {
                        const map = mapRef.current;
                        isCutting = false;
                        cutLineCoords = [];
                        map.off('mousemove', onMouseMove);
                        map.off('click', onMapClick);
                        removeCuttingLayer();

                        // Reiniciar el proceso de corte si la herramienta sigue activa
                        if (activeToolRef.current === 'cut') {
                            map.on('click', onMapClick);
                        }
                    }
                };

                const updateCuttingLayer = (coordinates) => {
                    if (mapRef.current) {
                        const map = mapRef.current;
                        const source = map.getSource('cutting-line');
                        if (source) {
                            source.setData({
                                type: 'Feature',
                                geometry: {
                                    type: 'LineString',
                                    coordinates: coordinates,
                                },
                            });
                        } else {
                            map.addSource('cutting-line', {
                                type: 'geojson',
                                data: {
                                    type: 'Feature',
                                    geometry: {
                                        type: 'LineString',
                                        coordinates: coordinates,
                                    },
                                },
                            });
                            map.addLayer({
                                id: 'cutting-line-layer',
                                type: 'line',
                                source: 'cutting-line',
                                paint: {
                                    'line-color': '#0000ff',
                                    'line-width': 2,
                                    'line-dasharray': [2, 4],
                                },
                            });
                        }
                    }
                };

                const removeCuttingLayer = () => {
                    if (mapRef.current) {
                        const map = mapRef.current;
                        if (map.getLayer('cutting-line-layer')) {
                            map.removeLayer('cutting-line-layer');
                        }
                        if (map.getSource('cutting-line')) {
                            map.removeSource('cutting-line');
                        }
                    }
                };

                map.on('click', onMapClick);

                currentToolCleanupRef.current = () => {
                    map.off('click', onMapClick);
                    map.off('mousemove', onMouseMove);
                };
            } else {
                console.warn('handleCutLine: No map reference or lines available.');
            }
        }
    }, [activeTool, deactivateTool, autoPanMap, lines, setLines, setActionHistory]);

    const handleDeleteLine = () => {
        if (activeTool === 'delete') {
            deactivateTool();
            setActiveTool(null);
        } else {
            deactivateTool();
            setActiveTool('delete');
        }
    };

    const handleUndo = () => {
        if (actionHistory.length > 0) {
            const lastAction = actionHistory[actionHistory.length - 1];
            let newLines = [...lines];

            switch (lastAction.type) {
                case 'cut':
                    newLines = lastAction.originalLines;
                    break;
                case 'draw':
                    newLines = newLines.filter((line) => line.id !== lastAction.line.id);
                    break;
                case 'delete':
                    newLines = [...newLines, lastAction.line];
                    break;
                default:
                    break;
            }

            setLines(newLines);
            setActionHistory(actionHistory.slice(0, -1));
        }
    };

    const handleLabelClick = useCallback(
        (text, color) => {
            setLabelsAndColors({ text, color });
        },
        [setLabelsAndColors]
    );

    const toggleUnfilteredLines = () => setShowUnfilteredLines((prevState) => !prevState);

    useEffect(() => {
        if (isFilteringLines && lines.length > 0 && activarEdicionInteractiva) {
            setActiveFilter("aerialApplicationsTraslapeDrones");
            setIsFilteringLines(false);
        }
    }, [isFilteringLines, lines, activarEdicionInteractiva]);

    useEffect(() => {
        activeToolRef.current = activeTool;
    }, [activeTool]);

    return (
        <>
            <CommonMap
                center={mapCenter}
                zoom={zoom}
                polygons={polygons}
                setPolygons={setPolygons}
                lines={lines}
                points={null}
                hullPolygon={null}
                nonIntersectedAreas={nonIntersectedAreas}
                bufferedLines={bufferedLines}
                bufferedIntersections={bufferedIntersections}
                onLineHover={handleLineHover}
                onLineMouseOut={handleLineMouseOut}
                onLineClick={handleLineClick}
                polygonProperties={polygonsProperties}
                showIntersections={showIntersections}
                mapRef={mapRef}
                userId={userData.userId}
                stretchPoints={stretchPoints}
                lineasNoFiltradas={showUnfilteredLines ? lineasNoFiltradas : []}
                highlightedLote={highlightedLote}
                polygonsData={polygonsData}
                activeLotes={activeLotes}
                onLeaveLote={onLeaveLote}
                onSelectLote={onSelectLote}
                onHoverLote={onHoverLote}
                areasSuperpuestas={areasSuperpuestas}
                setImgLaflet={setImgLaflet}
                polygonsLayerRef={polygonsLayerRef}
                setLines={setLines}
                popupInfo={popupInfo}
                setPopupInfo={setPopupInfo}
            />
            {isKml && (
                <FloatingToolsAerialApplications
                    handleCutLine={handleCutLine}
                    handleDrawLine={handleDrawLine}
                    handleDeleteLine={handleDeleteLine}
                    handleToggleBuffer={handleToggleBuffer}
                    handleUndo={handleUndo}
                    handleStretchLine={null}
                    activeTool={activeTool}
                    isBufferActive={isBufferActive}
                    bufferValue={bufferValue}
                    setBufferValue={setBufferValue}
                    showUnfilteredLines={showUnfilteredLines}
                    toggleUnfilteredLines={toggleUnfilteredLines}
                />
            )}

            {(polygons.length > 0 || lines.length > 0) && (
                <BarIndicator
                    filterType={activeFilter ? activeFilter : 'aerialApplicationsTraslape'}
                    isHistory={false}
                    onLabelClick={handleLabelClick}
                    setLabelsAndColors={setLabelsAndColors}
                />
            )}

            <MapDialog
                isOpen={isFilterDialogOpen}
                onClose={closeFilterDialog}
                availableFilters={availableFilters}
                handleToggleFilter={handleToggleFilter}
                filterSpeed={filterSpeed}
                filterAltitude={filterAltitude}
                filterRealDose={filterRealDose}
                lowSpeed={lowSpeed}
                medSpeed={medSpeed}
                highSpeed={highSpeed}
                setLowSpeed={setLowSpeed}
                setMedSpeed={setMedSpeed}
                setHighSpeed={setHighSpeed}
                lowAltitude={lowAltitude}
                medAltitude={medAltitude}
                highAltitude={highAltitude}
                setLowAltitude={setLowAltitude}
                setMedAltitude={setMedAltitude}
                setHighAltitude={setHighAltitude}
                lowRealDose={lowRealDose}
                medRealDose={medRealDose}
                highRealDose={highRealDose}
                setLowRealDose={setLowRealDose}
                setMedRealDose={setMedRealDose}
                setHighRealDose={setHighRealDose}
                setTipoMapeo={setTipoMapeo}
            />

            <Snackbar
                open={!areasCalculated && lines.length > 0}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert severity="warning" variant="filled" sx={{ width: '100%' }}>
                    <strong>Advertencia:</strong> Genera el buffer en las líneas para mostrar las áreas en el dashboard.
                </Alert>
            </Snackbar>
        </>
    );
};

export default AerialApplications;
