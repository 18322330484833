import { styled } from "@mui/system";
import { Box, IconButton } from "@mui/material";

export const ContentContainer = styled(Box)({
    padding: '20px',
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    maxHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    position: 'relative'
});

export const DownloadButton = styled(IconButton)({
    flexShrink: 0,
    marginLeft: '8px'
});

export const ScrollableSection = styled(Box)({
    maxHeight: '200px',
    overflowY: 'auto',
    overflowX: 'hidden',
});

export const MapPreviewContainer = styled(Box)(({ top, left, visible }) => ({
    position: 'fixed',
    top: top,
    left: left,
    width: '300px',
    height: '300px',
    zIndex: 9999,
    border: '1px solid #ccc',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 2px 10px rgba(0,0,0,0.3)',
    background: '#fff',
    opacity: visible ? 1 : 0,
    transition: 'opacity 0.2s ease-in-out',
}));
