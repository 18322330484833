import { styled } from '@mui/system';
import { AppBar, Button } from '@mui/material';
import { red } from '@mui/material/colors';

export const FilterBar = styled(AppBar)(
    ({ isSidebarOpen, isDashboardIndicators, isSmallScreen }) => ({
        backgroundColor: '#fff',
        color: '#000',
        boxShadow: 'none',
        borderBottom: '1px solid #e0e0e0',
        padding: '16px 8px 0 150px',
        position: 'fixed',
        top: isDashboardIndicators ? '50px' : '135px',
        width: '100%',
        transition: 'width 0.5s ease',
        zIndex: 1001,
        display: 'flex',
        alignItems: 'center',

    })
);

export const FilterButton = styled(Button)({
    margin: '0 8px',
    textTransform: 'none',
    color: '#000',
    fontWeight: 'bold',
});

export const ResetButton = styled(Button)({
    marginLeft: '8px',
    color: red[600],
    textTransform: 'none',
    fontWeight: 'bold',
});

export const ButtonsContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    flexShrink: 0,
});
