import React, { useState, useEffect, useRef, useMemo } from 'react';
import Map, { Source, Layer, NavigationControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import JSZip from 'jszip';
import shp from 'shpjs';
import {
    Box,
    Button,
    Typography,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    CircularProgress,
} from '@mui/material';
import { Root, InfoPanel, MapPanel, CustomPaper, LoaderOverlay } from './PreviewLotsStyle';
import axios from 'axios';
import { API_BASE_URL } from '../../utils/config';
import { toast } from 'react-toastify';
import ProgressBar from '../ProgressBar/ProgressBar';
import * as turf from '@turf/turf';

const PreviewLots = ({ userId }) => {
    const [polygons, setPolygons] = useState([]);
    const [area, setArea] = useState(0);
    const [historial, setHistorial] = useState([]);
    const [selectedLote, setSelectedLote] = useState('');
    const [loading, setLoading] = useState(true);
    const [limiteHectareas, setLimiteHectareas] = useState(0);
    const mapRef = useRef();

    const [loadingBarProgress, setLoadingBarProgress] = useState(0);
    const [showProgressBar, setShowProgressBar] = useState(false);

    const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/satellite-v9');
    const [selectedStyle, setSelectedStyle] = useState('Satélite');
    const [mapLoaded, setMapLoaded] = useState(false);

    const mapStyles = {
        Satélite: 'mapbox://styles/mapbox/satellite-v9',
        'Mapas de calles': 'mapbox://styles/mapbox/streets-v12',
        Terreno: 'mapbox://styles/mapbox/terrain-v2',
        Exterior: 'mapbox://styles/mapbox/outdoors-v12',
        'Satélite con calles': 'mapbox://styles/mapbox/satellite-streets-v12',
        'Mapa claro': 'mapbox://styles/mapbox/light-v10',
    };

    useEffect(() => {
        const obtenerLoteMasReciente = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    `${API_BASE_URL}configuration/lotesIniciales/masReciente/${userId}`
                );
                const geojson = response.data.content;
                setPolygons(geojson.features);
                const totalArea = geojson.features.reduce((sum, feature) => {
                    const polygonArea = turf.area(feature);
                    return sum + polygonArea;
                }, 0);

                setArea(totalArea);
            } catch (error) {
                console.error('Error al obtener el archivo más reciente', error);
            } finally {
                setLoading(false);
            }
        };

        const obtenerLimiteHectareas = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}configuration/empresaPorUsuario/${userId}`);
                const empresa = response.data;

                setLimiteHectareas(empresa.limiteHectareas);
            } catch (error) {
                console.error('Error al obtener los datos de la empresa', error);
            }
        };
        obtenerLoteMasReciente();
        obtenerLimiteHectareas();
    }, [userId]);

    useEffect(() => {
        const obtenerHistorial = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}configuration/lotesIniciales/historial/${userId}`);
                setHistorial(response.data);
            } catch (error) {
                console.error('Error al obtener el historial de archivos', error);
            }
        };

        obtenerHistorial();
    }, [userId]);

    const handleLoteSelect = async (event) => {
        const selectedFile = event.target.value;
        setSelectedLote(selectedFile);
        setLoading(true);

        try {
            const response = await axios.get(`${API_BASE_URL}configuration/lotesIniciales/${selectedFile}`);
            const geojson = response.data.content;
            setPolygons(geojson.features);

            const totalArea = geojson.features.reduce((sum, feature) => {
                const polygonArea = turf.area(feature);
                return sum + polygonArea;
            }, 0);

            setArea(totalArea);
        } catch (error) {
            console.error('Error al obtener el archivo seleccionado', error);
        } finally {
            setLoading(false);
        }
    };

    const handleZipUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setLoading(true);
            setLoadingBarProgress(0);
            setShowProgressBar(true);
            try {
                const zip = new JSZip();

                const content = await zip.loadAsync(file, {
                    progress: (metadata) => {
                        const percent = Math.floor((metadata.percent * 80) / 100);
                        setLoadingBarProgress(percent);
                    },
                });

                const shapefileBuffers = [];

                const filesCount = Object.keys(content.files).filter((filename) =>
                    filename.endsWith('.shp') ||
                    filename.endsWith('.shx') ||
                    filename.endsWith('.dbf') ||
                    filename.endsWith('.prj')
                ).length;

                let processedFiles = 0;

                for (const filename of Object.keys(content.files)) {
                    if (
                        filename.endsWith('.shp') ||
                        filename.endsWith('.shx') ||
                        filename.endsWith('.dbf') ||
                        filename.endsWith('.prj')
                    ) {
                        const arrayBuffer = await content.files[filename].async('arraybuffer');
                        shapefileBuffers.push({ filename, arrayBuffer });
                        processedFiles += 1;
                        const progress = 80 + Math.floor((processedFiles * 20) / filesCount);
                        setLoadingBarProgress(progress);
                    }
                }

                const shapefileArrayBuffers = {};
                shapefileBuffers.forEach(({ filename, arrayBuffer }) => {
                    const extension = filename.split('.').pop().toLowerCase();
                    shapefileArrayBuffers[extension] = arrayBuffer;
                });

                const geojson = await shp(shapefileArrayBuffers);
                setPolygons(geojson.features);

                const totalAreaHectareas = geojson.features.reduce((sum, feature) => {
                    return sum + turf.area(feature) / 10000;
                }, 0);
                setArea(totalAreaHectareas);

                if (totalAreaHectareas > limiteHectareas) {
                    toast.error('El lote supera el límite de hectáreas contratadas.');
                    setShowProgressBar(false);
                    setLoading(false);
                    return;
                }

                const data = {
                    userId: userId,
                    geojson: geojson,
                };

                await axios.post(`${API_BASE_URL}configuration/lotesIniciales`, data);

                setLoading(false);
                setShowProgressBar(false);
                toast.success('Lote subido y mapeado correctamente');

                const response = await axios.get(`${API_BASE_URL}configuration/lotesIniciales/historial/${userId}`);
                setHistorial(response.data);
                setSelectedLote('');
            } catch (error) {
                console.error('Error processing shapefile: ', error);
                setShowProgressBar(false);
                setLoading(false);
                toast.error('Error al subir el lote');
            }
        }
    };

    const getLoteId = (properties) => {
        const keys = Object.keys(properties).map((key) => key.toLowerCase());
        const idLoteIndex = keys.indexOf('id_lote');
        const idIndex = keys.indexOf('id');

        if (idLoteIndex !== -1) {
            return properties[Object.keys(properties)[idLoteIndex]];
        } else if (idIndex !== -1) {
            return properties[Object.keys(properties)[idIndex]];
        } else {
            return null;
        }
    };

    const polygonData = useMemo(() => {
        return {
            type: 'FeatureCollection',
            features: polygons.map((feature) => {
                const loteId = getLoteId(feature.properties);
                return {
                    ...feature,
                    properties: {
                        ...feature.properties,
                        loteId,
                    },
                };
            }),
        };
    }, [polygons]);

    useEffect(() => {
        if (mapLoaded && mapRef.current && polygons.length > 0) {
            const map = mapRef.current.getMap();
            const collection = {
                type: 'FeatureCollection',
                features: polygons,
            };
            const bbox = turf.bbox(collection);
            map.fitBounds(
                [
                    [bbox[0], bbox[1]],
                    [bbox[2], bbox[3]],
                ],
                { padding: 20 }
            );
        }
    }, [mapLoaded, polygons]);

    return (
        <Root>
            <InfoPanel>
                <CustomPaper>
                    <Typography variant="h6">Información del Lote</Typography>
                    <Typography>Área total: {area ? (area / 10000).toFixed(2) : 0} ha</Typography>
                    <List style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        {Object.entries(
                            polygons.reduce((acc, feature) => {
                                const loteId = getLoteId(feature.properties);
                                if (!acc[loteId]) {
                                    acc[loteId] = [];
                                }
                                acc[loteId].push(feature);
                                return acc;
                            }, {})
                        ).map(([loteId, features]) => (
                            <ListItem key={loteId}>
                                <ListItemText
                                    primary={`Lote ID: ${loteId}`}
                                    secondary={`Número de polígonos: ${features.length}`}
                                />
                            </ListItem>
                        ))}
                    </List>
                    <FormControl fullWidth>
                        <InputLabel id="select-lote-label">Seleccionar Lote</InputLabel>
                        <Select
                            labelId="select-lote-label"
                            value={selectedLote}
                            onChange={handleLoteSelect}
                            sx={{
                                backgroundColor: 'white',
                                color: 'black',
                                '& .MuiSelect-select': {
                                    backgroundColor: 'white',
                                },
                            }}
                        >
                            {historial.map((file, index) => (
                                <MenuItem key={index} value={file.name}>
                                    {new Date(file.created).toLocaleString()}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </CustomPaper>
                <Button variant="contained" component="label" style={{ marginTop: '16px' }}>
                    Subir Lotes
                    <input type="file" hidden onChange={handleZipUpload} />
                </Button>
            </InfoPanel>
            <MapPanel>
                {loading && (
                    <LoaderOverlay>
                        <CircularProgress />
                    </LoaderOverlay>
                )}
                <Map
                    initialViewState={{
                        longitude: 0,
                        latitude: 0,
                        zoom: 2,
                    }}
                    mapStyle={mapStyle}
                    mapboxAccessToken="pk.eyJ1IjoiamRtYW4iLCJhIjoiY20xNGpkN2d0MHlsejJ4cTA1Z3lwdGNhMCJ9.O5UY8q02UPPoCCMrobQ0sA"
                    style={{ height: '100%', width: '100%' }}
                    ref={mapRef}
                    projection="globe"
                    onLoad={() => {
                        setMapLoaded(true);
                    }}
                >
                    <NavigationControl position="top-right" />

                    {polygonData.features.length > 0 && (
                        <Source id="polygons" type="geojson" data={polygonData}>
                            <Layer
                                id="polygons-layer"
                                type="fill"
                                paint={{
                                    'fill-color': '#ffee33',
                                    'fill-opacity': 0.8,
                                    'fill-outline-color': '#ffee33',
                                }}
                            />
                        </Source>
                    )}

                    <Box position="absolute" top={10} left={10} zIndex={1}>
                        <Select
                            value={selectedStyle}
                            onChange={(e) => {
                                const selected = e.target.value;
                                setSelectedStyle(selected);
                                setMapStyle(mapStyles[selected]);
                            }}
                            sx={{
                                backgroundColor: 'white',
                                color: 'black',
                                '& .MuiSelect-select': {
                                    backgroundColor: 'white',
                                },
                            }}
                        >
                            {Object.keys(mapStyles).map((styleName) => (
                                <MenuItem key={styleName} value={styleName}>
                                    {styleName}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Map>
            </MapPanel>

            <ProgressBar
                progress={loadingBarProgress}
                message="Subiendo y procesando lote..."
                show={showProgressBar}
                title="Cargando Lote"
            />
        </Root>
    );
};

export default PreviewLots;
