// DashboardIndicators.jsx

import React, {
    useContext,
    useEffect,
    useState,
    useCallback,
    useRef,
} from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import PersonIcon from '@mui/icons-material/Person';
import BuildIcon from '@mui/icons-material/Build';
import FlightIcon from '@mui/icons-material/Flight';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import axios from 'axios';
import { toast } from 'react-toastify';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import {
    DashboardContainer,
    ContentContainer,
    RightPanel,
    CardsContainer,
    ChartCardContainer,
    InProgressMessage,
    TooltipContainer,
    ModalContent,
} from './DashboardIndicatorsStyle';
import FilterToolbar from '../../components/FilterToolbar/FilterToolbar';
import ActivitiesComponent from '../../components/ActivitiesComponent/ActivitiesComponent';
import CardData from '../../components/CardData/DataCard';
import ChartCard from '../../components/ChartCard/ChartCard';
import NoDataMessage from '../../components/NoDataMessage/NoDataMessage';
import {
    API_BASE_URL_DASHBOARD,
    API_BASE_URL,
} from '../../utils/config';
import { SidebarContext } from '../../context/SidebarContext';
import analysisConfig from '../../utils/analysisConfig';
import Loader from '../../components/Loader/Loader';
import Modal from '@mui/material/Modal';
import SettingsIcon from '@mui/icons-material/Settings';
import Tutorial from '../../components/Tutorial/Tutorial';

const ITEM_TYPE = 'CARD';

// Definimos las actividades que están en desarrollo
const activitiesInProgress = ['HERBICIDAS', 'FERTILIZACION', 'SIEMBRA'];

const DashboardIndicators = ({ isSidebarOpen }) => {
    const [filterOptions, setFilterOptions] = useState([]);
    const [dataCardsOrder, setDataCardsOrder] = useState([]);
    const [chartCardsOrder, setChartCardsOrder] = useState([]);
    const [loading, setLoading] = useState(true);
    const [promedioIndicadores, setPromedioIndicadores] = useState([]);
    const [porcentajes, setPorcentajes] = useState([]);
    const [metricasMaquina, setMetricasMaquina] = useState([]);
    const [porcentajesMaquina, setPorcentajesMaquina] = useState([]);
    const [metricasOperador, setMetricasOperador] = useState([]);
    const [porcentajesOperador, setPorcentajesOperador] = useState([]);
    const [metricasFinca, setMetricasFinca] = useState([]);
    const [tiempoTotal, setTiempoTotal] = useState([]);
    const [dosisTeoricaReal, setDosisTeoricaReal] = useState([]);
    const [porcentajesFinca, setPorcentajesFinca] = useState([]);
    const [limites, setLimites] = useState({});
    const [isGeneratingReport, setIsGeneratingReport] = useState(false); // Estado para el loader de generación de reporte

    const userData = JSON.parse(localStorage.getItem('userData'));
    const { selectedSidebarOption } = useContext(SidebarContext);
    const [selectedActivity, setSelectedActivity] = useState('COSECHA_MECANICA');
    const [shouldFetch, setShouldFetch] = useState(true);

    // Estados para filtros de Cosecha Mecánica
    const [filtersCM, setFiltersCM] = useState({
        loteOptions: [],
        operadorOptions: [],
        fincasOptions: [],
        zafrasOptions: [],
        tercioOptions: [],
    });

    // Estados para filtros de Aplicaciones Aéreas
    const [filtersAA, setFiltersAA] = useState({
        operadorOptionsAA: [],
        fincasOptionsAA: [],
        productosOptionsAA: [],
        zafraOptionsAA: [],
        loteOptionsAA: [],
        tercioOptionsAA: [],
    });

    // Estado para controlar si los filtros ya fueron obtenidos
    const [filtersFetched, setFiltersFetched] = useState({
        COSECHA_MECANICA: false,
        APLICACIONES_AEREAS: false,
    });

    const [selectedFilters, setSelectedFilters] = useState({});

    const [dateRange, setDateRange] = useState({
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
    });

    // Mapeo de estilos para gráficos
    const chartStyles = {
        // Cosecha Mecánica
        'metricas-finca': {
            color: '#4caf50', // Verde
            icon: <AgricultureIcon />,
        },
        'porcentajes-finca': {
            colors: ['#81c784', '#4caf50', '#66bb6a'], // Verde claro
            icon: <AgricultureIcon />,
        },
        'metricas-operador': {
            color: '#ff9800', // Naranja
            icon: <PersonIcon />,
        },
        'porcentajes-operador': {
            colors: ['#ffb74d', '#ffa726', '#fb8c00'], // Naranja claro
            icon: <PersonIcon />,
        },
        'metricas-maquina': {
            color: '#2196f3', // Azul
            icon: <BuildIcon />,
        },
        'porcentajes-maquina': {
            colors: ['#64b5f6', '#42a5f5', '#1e88e5'], // Azul claro
            icon: <BuildIcon />,
        },
        // Aplicaciones Aéreas
        'promedio-indicadores': {
            color: '#9c27b0', // Morado
            icon: <FlightIcon />,
        },
        'tiempo-total': {
            color: '#f44336', // Rojo
            icon: <AccessTimeIcon />,
        },
        'teorica-real': {
            color: '#009688', // Verde azulado
            icon: <ShowChartIcon />,
        },
        // Agrega más estilos si es necesario
    };

    // Mapeo de títulos de filtros para normalizar los nombres
    const filterTitles = {
        fechaInicio: 'Fecha Inicio',
        fechaFin: 'Fecha Fin',
        tipoAeronave: 'Tipo de Aeronave',
        operadorAA: 'Operador',
        fincaAA: 'Finca',
        productoAA: 'Producto',
        zafraAA: 'Zafra',
        loteAA: 'Lote',
        tercioAA: 'Tercio',
        zafraCM: 'Zafra',
        tercioCM: 'Tercio',
        fincaCM: 'Finca',
        operadorCM: 'Operador',
        loteCM: 'Lote',
        // Agrega más si es necesario
    };

    // Variables adicionales necesarias
    const [tooltip, setTooltip] = useState({
        visible: false,
        content: '',
        position: { top: 0, left: 0 },
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isTutorialActive, setIsTutorialActive] = useState(false);

    // Obtener el orden de las tarjetas desde el backend
    const fetchCardOrder = useCallback(async () => {
        try {
            const idUsuario = userData.userId;
            const response = await axios.get(
                `${API_BASE_URL_DASHBOARD}api/indicators/order/${idUsuario}/${selectedActivity}`
            );
            const { order } = response.data;
            const parsedOrder = JSON.parse(order);

            if (parsedOrder.dataCards) setDataCardsOrder(parsedOrder.dataCards);
            if (parsedOrder.chartCards) setChartCardsOrder(parsedOrder.chartCards);
        } catch (error) {
            console.error('Error fetching card order:', error);
        }
    }, [userData.userId, selectedActivity]);

    useEffect(() => {
        fetchCardOrder();
    }, [fetchCardOrder]);

    const handleDateChange = useCallback((dates) => {
        setDateRange(dates);
        // No actualizar datos hasta que se presione "Filtrar"
    }, []);

    // Construir los filtros basados en la actividad seleccionada
    useEffect(() => {
        let filters = [];

        // Añadimos siempre los DatePickers (aunque se manejan por separado en el componente de filtros)
        filters.push({ label: 'Fecha Inicio', value: 'fechaInicio' });
        filters.push({ label: 'Fecha Fin', value: 'fechaFin' });

        if (selectedActivity === 'APLICACIONES_AEREAS') {
            filters.push({
                label: 'Tipo de Aeronave',
                value: 'tipoAeronave',
                options: ['DRONE', 'AVIONETA', 'HELICOPTERO'],
            });
            filters.push({ label: 'Operador', value: 'operadorAA' });
            filters.push({ label: 'Finca', value: 'fincaAA' });
            filters.push({ label: 'Producto', value: 'productoAA' });
            filters.push({ label: 'Zafra', value: 'zafraAA' });
            filters.push({ label: 'Lote', value: 'loteAA' });
            filters.push({ label: 'Tercio', value: 'tercioAA' });
        } else if (selectedActivity === 'COSECHA_MECANICA') {
            filters.push({ label: 'Zafra', value: 'zafraCM' });
            filters.push({ label: 'Tercio', value: 'tercioCM' });
            filters.push({ label: 'Finca', value: 'fincaCM' });
            filters.push({ label: 'Operador', value: 'operadorCM' });
            filters.push({ label: 'Lote', value: 'loteCM' });
        }

        setFilterOptions(filters);
    }, [selectedActivity]);

    const fetchIndicators = useCallback(async () => {
        const config = analysisConfig[selectedActivity];
        if (config?.fetchDataIndicators && dateRange.startDate && dateRange.endDate) {
            try {
                const startDateFormatted = moment(dateRange.startDate)
                    .startOf('day')
                    .format('DD/MM/YYYY');
                const endDateFormatted = moment(dateRange.endDate)
                    .endOf('day')
                    .format('DD/MM/YYYY');

                const idUsuario = userData.userId;

                setLoading(true);
                // Resetear los datos de indicadores
                setPromedioIndicadores([]);
                setPorcentajes([]);
                setMetricasMaquina([]);
                setPorcentajesMaquina([]);
                setMetricasOperador([]);
                setPorcentajesOperador([]);
                setMetricasFinca([]);
                setPorcentajesFinca([]);
                setTiempoTotal([]);
                setDosisTeoricaReal([]);

                // Obtener límites desde el backend
                const limitesResponse = await axios.get(
                    `${API_BASE_URL}configuration/limites/${selectedActivity.toLowerCase()}`
                );
                const fetchedLimites = limitesResponse.data.limites.reduce(
                    (acc, limit) => {
                        acc[limit.indicador] = { max: limit.max, min: limit.min };
                        return acc;
                    },
                    {}
                );
                setLimites(fetchedLimites);

                const minMaxParams = {
                    min: JSON.stringify(fetchedLimites).includes('min') ? fetchedLimites : {},
                    max: JSON.stringify(fetchedLimites).includes('max') ? fetchedLimites : {},
                };

                if (selectedActivity === 'COSECHA_MECANICA') {
                    const [
                        promedioResult,
                        porcentajesResult,
                        metricasMaquinaResult,
                        porcentajesMaquinaResult,
                        metricasOperadorResult,
                        porcentajesOperadorResult,
                        metricasFincaResult,
                        porcentajesFincaResult,
                    ] = await Promise.all([
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'promedio-indicadores',
                            idUsuario,
                            minMaxParams,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'porcentajes',
                            idUsuario,
                            minMaxParams,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'metricas-maquina',
                            idUsuario,
                            minMaxParams,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'porcentajes-maquina',
                            idUsuario,
                            minMaxParams,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'metricas-operador',
                            idUsuario,
                            minMaxParams,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'porcentajes-operador',
                            idUsuario,
                            minMaxParams,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'metricas-finca',
                            idUsuario,
                            minMaxParams,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'porcentajes-finca',
                            idUsuario,
                            minMaxParams,
                            selectedFilters
                        ),
                    ]);

                    setPromedioIndicadores(
                        promedioResult.length > 0 ? promedioResult : []
                    );
                    setPorcentajes(
                        porcentajesResult.length > 0 ? porcentajesResult : []
                    );
                    setMetricasMaquina(
                        metricasMaquinaResult.length > 0 ? metricasMaquinaResult : []
                    );
                    setPorcentajesMaquina(
                        porcentajesMaquinaResult.length > 0
                            ? porcentajesMaquinaResult
                            : []
                    );
                    setMetricasOperador(
                        metricasOperadorResult.length > 0 ? metricasOperadorResult : []
                    );
                    setPorcentajesOperador(
                        porcentajesOperadorResult.length > 0
                            ? porcentajesOperadorResult
                            : []
                    );
                    setMetricasFinca(
                        metricasFincaResult.length > 0 ? metricasFincaResult : []
                    );
                    setPorcentajesFinca(
                        porcentajesFincaResult.length > 0 ? porcentajesFincaResult : []
                    );
                } else if (selectedActivity === 'APLICACIONES_AEREAS') {
                    const selectedAircraft = selectedFilters.tipoAeronave || '';

                    const [
                        promedioResult,
                        metricasFincaResult,
                        tiempoTotalResult,
                        dosisTeoricaRealResult,
                    ] = await Promise.all([
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'promedio-indicadores',
                            idUsuario,
                            minMaxParams,
                            selectedAircraft,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'metricas-finca',
                            idUsuario,
                            minMaxParams,
                            selectedAircraft,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'tiempo-total',
                            idUsuario,
                            minMaxParams,
                            selectedAircraft,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'teorica-real',
                            idUsuario,
                            minMaxParams,
                            selectedAircraft,
                            selectedFilters
                        ),
                    ]);

                    setPromedioIndicadores(
                        promedioResult.length > 0 ? promedioResult : []
                    );
                    setMetricasFinca(
                        metricasFincaResult.length > 0 ? metricasFincaResult : []
                    );
                    setTiempoTotal(
                        tiempoTotalResult.length > 0 ? tiempoTotalResult : []
                    );
                    setDosisTeoricaReal(
                        dosisTeoricaRealResult.length > 0 ? dosisTeoricaRealResult : []
                    );
                }

                setLoading(false);
                setShouldFetch(false);
            } catch (error) {
                console.error('Error fetching indicators:', error);
                setLoading(false);
                setShouldFetch(false);
            }
        }
    }, [selectedActivity, dateRange, userData.userId, selectedFilters]);

        useEffect(() => {
            if (shouldFetch) {
                fetchIndicators();
            }
        }, [shouldFetch, fetchIndicators]);

        const handleFilterClick = (filters) => {
            const { startDate, endDate } = dateRange;

            const diffInDays = endDate.diff(startDate, 'days');

            if (diffInDays > 31) {
                toast.warn('El rango de fechas no puede ser mayor a 31 días.');
            } else {
                setSelectedFilters(filters);
                setShouldFetch(true);
            }
        };

        const handleActivitySelect = (activity) => {
            if (activity !== selectedActivity) {
                setSelectedActivity(activity);
                setSelectedFilters({});
                setDateRange({
                    startDate: moment().startOf('month'),
                    endDate: moment().endOf('month'),
                });
                setShouldFetch(true);
            }
        };

        const formatValue = (value) => {
            return typeof value === 'number' ? value.toFixed(3) : value;
        };

        const getMinMaxFromLimits = (card) => {
            const limitName = card.limitName;
            const limit = limites[limitName] || { min: null, max: null };

            return {
                min: limit.min !== null ? limit.min : 'N/A',
                max: limit.max !== null ? limit.max : 'N/A',
            };
        };

        const saveOrder = async (orderType, newOrder) => {
            try {
                const idUsuario = userData.userId;
                const preferencesOrder = {
                    dataCards: orderType === 'dataCards' ? newOrder : dataCardsOrder,
                    chartCards: orderType === 'chartCards' ? newOrder : chartCardsOrder,
                };

                await axios.post(`${API_BASE_URL_DASHBOARD}api/indicators/order`, {
                    userId: idUsuario,
                    analysis: selectedActivity,
                    order: JSON.stringify(preferencesOrder),
                });

                if (orderType === 'dataCards') {
                    setDataCardsOrder(newOrder);
                } else if (orderType === 'chartCards') {
                    setChartCardsOrder(newOrder);
                }
            } catch (error) {
                console.error('Error saving order:', error);
            }
        };

        const moveCardHandler = (orderType, dragIndex, hoverIndex) => {
            const cardOrder =
                orderType === 'dataCards' ? dataCardsOrder : chartCardsOrder;
            const setCardOrder =
                orderType === 'dataCards' ? setDataCardsOrder : setChartCardsOrder;
            const draggedCard = cardOrder[dragIndex];

            const updatedOrder = [...cardOrder];
            updatedOrder.splice(dragIndex, 1);
            updatedOrder.splice(hoverIndex, 0, draggedCard);

            setCardOrder(updatedOrder);
            saveOrder(orderType, updatedOrder);
        };

        const CardComponent = ({ id, index, moveCard, children, orderType }) => {
            const ref = useRef(null);
            const [, drop] = useDrop({
                accept: ITEM_TYPE,
                hover(item) {
                    if (!ref.current) return;
                    const dragIndex = item.index;
                    const hoverIndex = index;

                    if (dragIndex === hoverIndex) return;

                    moveCard(orderType, dragIndex, hoverIndex);
                    item.index = hoverIndex;
                },
            });

            const [{ isDragging }, drag] = useDrag({
                type: ITEM_TYPE,
                item: { id, index },
                collect: (monitor) => ({
                    isDragging: monitor.isDragging(),
                }),
            });

            drag(drop(ref));

            return (
                <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
                    {children}
                </div>
            );
        };

        const mapChartData = (metrics, percentages, cardId) => {
            const combinedData = [];

            metrics.forEach((metric) => {
                if (metric[cardId]) {
                    combinedData.push({
                        name: metric._id,
                        value:
                            metric[cardId]?.value !== undefined ? metric[cardId].value : null,
                    });
                }
            });

            percentages.forEach((percentage) => {
                if (percentage[cardId]) {
                    combinedData.push({
                        name: percentage._id,
                        value:
                            percentage[cardId]?.value !== undefined
                                ? percentage[cardId].value
                                : null,
                    });
                }
            });

            return combinedData.filter(
                (item) => item.value !== null && item.value !== undefined
            );
        };

        // Función para obtener filtros de Cosecha Mecánica
        const fetchCosechaMecanicaFilters = async () => {
            try {
                const response = await axios.get(
                    `${API_BASE_URL_DASHBOARD}api/indicators/filters/cosecha-mecanica`
                );

                setFiltersCM({
                    loteOptions: response.data.lote || [],
                    operadorOptions: response.data.operadores || [],
                    fincasOptions: response.data.fincas || [],
                    zafrasOptions: response.data.zafras || [],
                    tercioOptions: response.data.tercios || [],
                });
                setFiltersFetched((prev) => ({ ...prev, COSECHA_MECANICA: true }));
            } catch (error) {
                console.error('Error fetching Cosecha Mecanica filters:', error);
            }
        };

        // Función para obtener filtros de Aplicaciones Aéreas
        const fetchAplicacionesAereasFilters = async () => {
            try {
                const response = await axios.get(
                    `${API_BASE_URL_DASHBOARD}api/indicators/filters/aplicaciones-aereas`
                );

                setFiltersAA({
                    operadorOptionsAA: response.data.operadores || [],
                    fincasOptionsAA: response.data.fincas || [],
                    productosOptionsAA: response.data.productos || [],
                    zafraOptionsAA: response.data.zafras || [],
                    loteOptionsAA: response.data.lotes || [],
                    tercioOptionsAA: response.data.tercios || [],
                });
                setFiltersFetched((prev) => ({ ...prev, APLICACIONES_AEREAS: true }));
            } catch (error) {
                console.error('Error fetching Aplicaciones Aereas filters:', error);
            }
        };

        // Obtener filtros según la actividad seleccionada
        useEffect(() => {
            if (!filtersFetched[selectedActivity]) {
                if (selectedActivity === 'COSECHA_MECANICA') {
                    fetchCosechaMecanicaFilters();
                } else if (selectedActivity === 'APLICACIONES_AEREAS') {
                    fetchAplicacionesAereasFilters();
                }
            }
        }, [selectedActivity, filtersFetched]);

        // Limpiar filtros al cambiar de actividad
        useEffect(() => {
            setSelectedFilters({});
            setDateRange({
                startDate: moment().startOf('month'),
                endDate: moment().endOf('month'),
            });
        }, [selectedActivity]);

        // Referencias para las gráficas
        const chartRefs = useRef({});

        // Función para convertir una imagen a Base64
        const convertImageToBase64 = (url) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.setAttribute('crossOrigin', 'anonymous');

                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;

                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);

                    const dataURL = canvas.toDataURL('image/png');
                    resolve(dataURL);
                };

                img.onerror = (error) => {
                    reject(error);
                };

                img.src = url;
            });
        };

        // Función para generar el reporte
        const handleGenerateReport = async () => {
            try {
                // Verificar si hay datos
                if (
                    dataCardsOrder.length === 0 ||
                    chartCardsOrder.length === 0 ||
                    loading
                ) {
                    toast.warn('No hay datos para generar el reporte.');
                    return;
                }

                // Obtener la zona horaria del usuario
                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

                // Usar moment para obtener la fecha y hora local formateada
                const now = moment();
                const formattedDateTime = now.format('dddd, D [de] MMMM [de] YYYY, h:mm A');
                const formattedFileDateTime = now.format('YYYYMMDD_HHmmss');

                // Convertir el logo a Base64
                let logoBase64 = '';
                try {
                    logoBase64 = await convertImageToBase64('/logo.png');
                } catch (error) {
                    console.error('Error al cargar el logo:', error);
                    toast.error('Error al cargar el logo para el reporte.');
                    return;
                }

                // Recopilar datos
                const reportData = {
                    activity: selectedActivity,
                    filters: {},
                    dataCards: [],
                    charts: [],
                    dateTime: formattedDateTime, // Enviamos la fecha y hora
                    logo: logoBase64, // Incluir el logo en Base64
                };

                // Normalizar los títulos de los filtros y agregar solo los que tienen valores
                for (const [key, value] of Object.entries(selectedFilters)) {
                    if (value !== null && value !== undefined && value !== '') {
                        const title = filterTitles[key] || key;
                        reportData.filters[title] = value;
                    }
                }

                // Recopilar los valores de las tarjetas de datos
                dataCardsOrder.forEach((card) => {
                    const dataValue =
                        promedioIndicadores[0]?.[card.id]?.value ||
                        porcentajes[0]?.[card.id]?.value ||
                        tiempoTotal[0]?.[card.id]?.value ||
                        dosisTeoricaReal[0]?.[card.id]?.value;

                    const hasData = dataValue !== undefined && dataValue !== null;

                    reportData.dataCards.push({
                        id: card.id,
                        title: card.title,
                        value: hasData ? formatValue(dataValue) : 'Sin datos',
                        dimensional: card.dimensional || '',
                    });
                });

                // Capturar las imágenes de las gráficas
                const chartImages = [];

                const chartRefEntries = Object.entries(chartRefs.current);
                for (const [cardId, chartRef] of chartRefEntries) {
                    if (chartRef && chartRef.current) {
                        try {
                            const dataUrl = await domtoimage.toPng(chartRef.current);
                            chartImages.push({
                                id: cardId,
                                title:
                                    chartCardsOrder.find((card) => card.id === cardId)
                                        ?.title || cardId,
                                image: dataUrl,
                            });
                        } catch (error) {
                            console.error(
                                'Error al capturar la imagen de la gráfica:',
                                error
                            );
                        }
                    }
                }

                reportData.charts = chartImages;

                // Ahora mostramos el loader
                setIsGeneratingReport(true);

                // Enviar datos al backend
                const response = await axios.post(
                    `${API_BASE_URL}reporteria/dashboard-indicators`,
                    reportData,
                    {
                        responseType: 'blob',
                    }
                );

                // Manejar la descarga del archivo
                const blob = new Blob([response.data], { type: 'application/pdf' });
                saveAs(blob, `dashboard_report_${formattedFileDateTime}.pdf`);
            } catch (error) {
                console.error('Error al generar el reporte:', error);
                toast.error('Error al generar el reporte');
            } finally {
                setIsGeneratingReport(false);
            }
        };

        return (
            <DndProvider backend={HTML5Backend}>
                <DashboardContainer isSidebarOpen={isSidebarOpen}>
                    {(isGeneratingReport) && <Loader />}

                    <FilterToolbar
                        key={selectedActivity}
                        isSidebarOpen={isSidebarOpen}
                        isDashboardIndicators={true}
                        filterOptions={filterOptions}
                        onDateChange={handleDateChange}
                        onFilterClick={handleFilterClick}
                        // Filtros para Cosecha Mecánica
                        loteOptions={filtersCM.loteOptions}
                        operadorOptions={filtersCM.operadorOptions}
                        fincasOptions={filtersCM.fincasOptions}
                        zafrasOptions={filtersCM.zafrasOptions}
                        tercioOptions={filtersCM.tercioOptions}
                        // Filtros para Aplicaciones Aéreas
                        operadorOptionsAA={filtersAA.operadorOptionsAA}
                        fincasOptionsAA={filtersAA.fincasOptionsAA}
                        productosOptionsAA={filtersAA.productosOptionsAA}
                        zafraOptionsAA={filtersAA.zafraOptionsAA}
                        loteOptionsAA={filtersAA.loteOptionsAA}
                        tercioOptionsAA={filtersAA.tercioOptionsAA}
                        dateRange={dateRange}
                        selectedActivity={selectedActivity}
                        // Pasamos la función para generar el reporte
                        onGenerateReport={handleGenerateReport}
                    />
                    <ActivitiesComponent
                        onSelectActivity={handleActivitySelect}
                        isSidebarOpen={isSidebarOpen}
                    />
                    <ContentContainer>
                        <RightPanel>
                            {activitiesInProgress.includes(selectedActivity) ? (
                                <InProgressMessage>
                                    ¡Estamos mejorando esta sección! Pronto podrás acceder a
                                    esta información.
                                </InProgressMessage>
                            ) : (
                                <CardsContainer>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            justifyContent: 'flex-start',
                                            width: '100%',
                                        }}
                                    >
                                        {dataCardsOrder.map((card, index) => {
                                            const { min, max } = getMinMaxFromLimits(card);
                                            const dataValue =
                                                promedioIndicadores[0]?.[card.id]?.value ||
                                                porcentajes[0]?.[card.id]?.value ||
                                                tiempoTotal[0]?.[card.id]?.value ||
                                                dosisTeoricaReal[0]?.[card.id]?.value;

                                            const hasData =
                                                dataValue !== undefined && dataValue !== null;

                                            // Obtener estilo del card
                                            const cardStyle = chartStyles[card.id] || {};
                                            const color = cardStyle.color;
                                            const icon = cardStyle.icon;

                                            return (
                                                <CardComponent
                                                    key={card.id}
                                                    id={card.id}
                                                    index={index}
                                                    moveCard={moveCardHandler}
                                                    orderType="dataCards"
                                                >
                                                    <CardData
                                                        title={card.title}
                                                        icon={icon}
                                                        color={color}
                                                        min={min}
                                                        max={max}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                height: '100%',
                                                            }}
                                                        >
                                                            {loading ? (
                                                                <CircularProgress />
                                                            ) : hasData ? (
                                                                `${formatValue(dataValue)} ${card.dimensional || ''}`
                                                            ) : (
                                                                <NoDataMessage />
                                                            )}
                                                        </div>
                                                    </CardData>
                                                </CardComponent>
                                            );
                                        })}
                                    </div>

                                    <div
                                        style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            justifyContent: 'flex-start',
                                            width: '100%',
                                        }}
                                    >
                                        {chartCardsOrder.map((card, index) => {
                                            const { min, max } = getMinMaxFromLimits(card);

                                            const isPercentageChart =
                                                card.id.includes('porcentaje') ||
                                                card.type === 'pie';
                                            const chartData = mapChartData(
                                                metricasMaquina,
                                                porcentajesMaquina,
                                                card.id
                                            ).length > 0
                                                ? mapChartData(
                                                    metricasMaquina,
                                                    porcentajesMaquina,
                                                    card.id
                                                )
                                                : mapChartData(
                                                    metricasOperador,
                                                    porcentajesOperador,
                                                    card.id
                                                ).length > 0
                                                    ? mapChartData(
                                                        metricasOperador,
                                                        porcentajesOperador,
                                                        card.id
                                                    )
                                                    : mapChartData(
                                                        metricasFinca,
                                                        porcentajesFinca,
                                                        card.id
                                                    );

                                            const hasData = chartData.length > 0;

                                            // Obtener estilo del gráfico
                                            const chartStyle = chartStyles[card.id] || {};
                                            const color = chartStyle.color;
                                            const icon = chartStyle.icon;
                                            const colors = chartStyle.colors;

                                            // Referencia para capturar la gráfica
                                            const chartRef = React.createRef();
                                            chartRefs.current[card.id] = chartRef;

                                            return (
                                                <ChartCardContainer key={card.id} ref={chartRef}>
                                                    <CardComponent
                                                        id={card.id}
                                                        index={index}
                                                        moveCard={moveCardHandler}
                                                        orderType="chartCards"
                                                    >
                                                        <ChartCard
                                                            title={card.title}
                                                            data={hasData ? chartData : []}
                                                            type={
                                                                isPercentageChart ? 'pie' : 'line'
                                                            }
                                                            color={color}
                                                            colors={colors}
                                                            icon={icon}
                                                            min={min}
                                                            max={max}
                                                        >
                                                            {loading && <CircularProgress />}
                                                        </ChartCard>
                                                    </CardComponent>
                                                </ChartCardContainer>
                                            );
                                        })}
                                    </div>
                                </CardsContainer>
                            )}
                        </RightPanel>
                    </ContentContainer>
                </DashboardContainer>

                {/** Tooltip */}
                {tooltip.visible && (
                    <TooltipContainer
                        top={tooltip.position.top}
                        left={tooltip.position.left}
                    >
                        {tooltip.content}
                    </TooltipContainer>
                )}

                {/** Modal */}
                <Modal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <ModalContent>
                        <SettingsIcon
                            style={{
                                fontSize: '4rem',
                                color: '#f5a623',
                                animation: 'rotate 2s linear infinite',
                            }}
                        />
                        <h2 id="modal-title">Sección en construcción</h2>
                        <p id="modal-description">{modalMessage}</p>
                    </ModalContent>
                </Modal>

                {/** Tutorial */}
                <Tutorial
                    isActive={isTutorialActive}
                    onClose={() => setIsTutorialActive(false)}
                />
            </DndProvider>
        );

    };

    export default DashboardIndicators;
