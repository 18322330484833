// ActivitiesComponentStyle.js

import { styled } from '@mui/system';
import { List, ListItem } from '@mui/material';
import { darken } from '@mui/material/styles';

export const HorizontalActivityList = styled(List)(({ isSidebarOpen }) => ({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#fff',
    padding: '8px 16px',
    zIndex: 999,
    height: '100px',
    boxSizing: 'border-box',
    gap: '16px',
    overflowX: 'auto',
    justifyContent: 'center',
    position: 'relative',
    top: '95px'
}));

export const HorizontalActivityItem = styled(ListItem)(({ bgColor }) => ({
    flex: '1 1 auto',
    maxWidth: '200px', // Opcional: limita el ancho máximo de los recuadros
    borderRadius: '8px',
    boxSizing: 'border-box',
    backgroundColor: bgColor,
    '&:hover': {
        backgroundColor: darken(bgColor, 0.1),
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px',
    minWidth: '0',
    textAlign: 'center',
}));

export const SelectedHorizontalActivityItem = styled(ListItem)(({ bgColor }) => ({
    flex: '1 1 auto',
    maxWidth: '200px', // Opcional: limita el ancho máximo de los recuadros
    borderRadius: '8px',
    backgroundColor: darken(bgColor, 0.2),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px',
    minWidth: '0',
    textAlign: 'center',
    color: '#ffffff',

    '& .MuiListItemIcon-root': {
        color: '#ffffff',
    },
    '& .MuiListItemText-primary': {
        color: '#ffffff',
    },
}));
