import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import Map, { Source, Layer, NavigationControl, Popup } from 'react-map-gl';
import { Box, Select, MenuItem, IconButton } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import HomeIcon from '@mui/icons-material/Home';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { styled } from '@mui/system';
import axios from 'axios';
import * as turf from '@turf/turf';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    marginTop: theme.spacing(1),
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
        backgroundColor: 'lightgray',
    },
    width: 36,
    right: 50,
    height: 36,
}));

const CommonMap = (props) => {
    const {
        center = [14.634915, -90.506882],
        zoom = 8,
        polygons = [],
        lines = [],
        points = [],
        areasSuperpuestas = [],
        nonIntersectedAreas = [],
        bufferedLines = [],
        bufferedIntersections = [],
        onLineHover = () => {},
        onLineMouseOut = () => {},
        onLineClick = () => {},
        polygonProperties = [],
        showIntersections = true,
        mapRef,
        polygonsData = [],
        highlightedLote = null,
        activeLotes = [],
        onSelectLote = () => {},
        onHoverLote = () => {},
        onLeaveLote = () => {},
        imageUrl = '',
        northWestCoords,
        southEastCoords,
        filteredColors,
        selectedLine,
        getLineColor,
        lineasNoFiltradas = [],
        popupInfo,
        setPopupInfo,
        selectedFiles
    } = props;

    console.log("ESTE ES EL SELECTED FILES DESDE COMMON MAP:", selectedFiles);

    const localMapRef = useRef(null);

    const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v12');
    const [selectedStyle, setSelectedStyle] = useState(() => {
        return localStorage.getItem('selectedMapStyle') || 'Satellite View (No Details)';
    });

    const [mapLoaded, setMapLoaded] = useState(false);

    const mapStyles = {
        'Satellite View (No Details)': 'mapbox://styles/mapbox/satellite-v9',
        'Street Map': 'mapbox://styles/mapbox/streets-v12',
        'Terrain Map': 'mapbox://styles/mapbox/terrain-v2',
        'Outdoors Map': 'mapbox://styles/mapbox/outdoors-v12',
        'Satellite Streets': 'mapbox://styles/mapbox/satellite-streets-v12',
        'Light Map': 'mapbox://styles/mapbox/light-v10',
    };

    useEffect(() => {
        setMapStyle(mapStyles[selectedStyle]);
        localStorage.setItem('selectedMapStyle', selectedStyle);
    }, [selectedStyle]);

    const handleMapLoad = useCallback(() => {
        setMapLoaded(true);
        if (mapRef && localMapRef.current) {
            mapRef.current = localMapRef.current.getMap();
        }
    }, [mapRef]);

    const getPolygonFeatures = useCallback((dataArray) => {
        if (!Array.isArray(dataArray)) return [];

        const features = dataArray
            .map((item) => {
                if (item && item.geometry && item.geometry.type && item.geometry.coordinates) {
                    let geometry = item.geometry;
                    if (geometry.type === 'Polygon') {
                        const coordinates = geometry.coordinates.map((ring) =>
                            ring.map((coord) => [coord[0], coord[1]])
                        );
                        return {
                            type: 'Feature',
                            geometry: {
                                type: 'Polygon',
                                coordinates: coordinates,
                            },
                            properties: item.properties || {},
                        };
                    } else if (geometry.type === 'MultiPolygon') {
                        const coordinates = geometry.coordinates.map((polygon) =>
                            polygon.map((ring) => ring.map((coord) => [coord[0], coord[1]]))
                        );
                        return {
                            type: 'Feature',
                            geometry: {
                                type: 'MultiPolygon',
                                coordinates: coordinates,
                            },
                            properties: item.properties || {},
                        };
                    } else {
                        return null;
                    }
                } else if (Array.isArray(item)) {
                    const coordinates = item.map((coord) => [coord[0], coord[1]]);
                    return {
                        type: 'Feature',
                        geometry: {
                            type: 'Polygon',
                            coordinates: [coordinates],
                            properties: {},
                        },
                    };
                } else {
                    return null;
                }
            })
            .filter((feature) => feature !== null);

        return features;
    }, []);

    const pointData = useMemo(() => {
        if (Array.isArray(points)) {
            const pointFeatures = points
                .map((point) => {
                    if (
                        point &&
                        point.geometry &&
                        Array.isArray(point.geometry.coordinates) &&
                        point.geometry.coordinates.length >= 2
                    ) {
                        const coords = [point.geometry.coordinates[0], point.geometry.coordinates[1]];
                        return {
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: coords,
                            },
                            properties: {
                                color: point.color || '#FF0000',
                            },
                        };
                    }
                    return null;
                })
                .filter(Boolean);
            return { type: 'FeatureCollection', features: pointFeatures };
        } else {
            return { type: 'FeatureCollection', features: [] };
        }
    }, [points]);

    const lineData = useMemo(() => {
        if (Array.isArray(lines)) {
            const lineFeatures = lines
                .map((line, index) => {
                    if (line && Array.isArray(line.latlngs)) {
                        const coordinates = line.latlngs
                            .map((latlng) => {
                                if (typeof latlng.lat === 'number' && typeof latlng.lng === 'number') {
                                    return [latlng.lng, latlng.lat];
                                }
                                return null;
                            })
                            .filter((coord) => coord !== null);
                        if (coordinates.length > 0) {
                            return {
                                type: 'Feature',
                                id: line.id || index,
                                geometry: {
                                    type: 'LineString',
                                    coordinates: coordinates,
                                },
                                properties: {
                                    ...line.properties,
                                    color: '#ff0000',
                                },
                            };
                        }
                    }
                    return null;
                })
                .filter(Boolean);
            return { type: 'FeatureCollection', features: lineFeatures };
        } else {
            return { type: 'FeatureCollection', features: [] };
        }
    }, [lines]);

    const lineasNoFiltradasData = useMemo(() => {
        if (Array.isArray(lineasNoFiltradas)) {
            const features = lineasNoFiltradas
                .map((line, index) => {
                    if (line && Array.isArray(line.latlngs)) {
                        const coordinates = line.latlngs
                            .map((latlng) => {
                                if (typeof latlng.lat === 'number' && typeof latlng.lng === 'number') {
                                    return [latlng.lng, latlng.lat];
                                }
                                return null;
                            })
                            .filter((coord) => coord !== null);
                        if (coordinates.length > 0) {
                            return {
                                type: 'Feature',
                                id: line.id || index,
                                geometry: {
                                    type: 'LineString',
                                    coordinates: coordinates,
                                },
                                properties: {
                                    ...line.properties,
                                    color: '#e0e0e0',
                                },
                            };
                        }
                    } else if (line && line.geometry) {
                        if (line.geometry.type === 'LineString' || line.geometry.type === 'MultiLineString') {
                            return {
                                type: 'Feature',
                                id: line.id || index,
                                geometry: line.geometry,
                                properties: {
                                    ...line.properties,
                                    color: '#e0e0e0',
                                },
                            };
                        }
                    }
                    return null;
                })
                .filter(Boolean);
            return { type: 'FeatureCollection', features };
        } else {
            return { type: 'FeatureCollection', features: [] };
        }
    }, [lineasNoFiltradas]);

    const polygonDataFeatures = useMemo(() => {
        if (Array.isArray(polygonsData)) {
            const features = polygonsData
                .map((feature, index) => {
                    if (
                        feature &&
                        feature.geometry &&
                        feature.geometry.type &&
                        Array.isArray(feature.geometry.coordinates)
                    ) {
                        if (feature.geometry.type === 'Polygon') {
                            const coordinates = feature.geometry.coordinates.map((ring) =>
                                ring.map((coord) => [coord[0], coord[1]])
                            );
                            return {
                                type: 'Feature',
                                id: index,
                                geometry: {
                                    type: 'Polygon',
                                    coordinates: coordinates,
                                },
                                properties: {
                                    ...feature.properties,
                                },
                            };
                        } else if (feature.geometry.type === 'MultiPolygon') {
                            const coordinates = feature.geometry.coordinates.map((polygon) =>
                                polygon.map((ring) => ring.map((coord) => [coord[0], coord[1]]))
                            );
                            return {
                                type: 'Feature',
                                id: index,
                                geometry: {
                                    type: 'MultiPolygon',
                                    coordinates: coordinates,
                                },
                                properties: {
                                    ...feature.properties,
                                },
                            };
                        } else {
                            return null;
                        }
                    }
                    return null;
                })
                .filter(Boolean);
            return { type: 'FeatureCollection', features };
        } else {
            return { type: 'FeatureCollection', features: [] };
        }
    }, [polygonsData]);

    const polygonDataMemo = useMemo(() => {
        if (Array.isArray(polygons)) {
            const polygonFeatures = polygons
                .map((polygonCoords, index) => {
                    if (Array.isArray(polygonCoords)) {
                        const isActive = activeLotes.includes(index);
                        const coordinates = polygonCoords.map((coord) => [coord[1], coord[0]]);
                        const properties = {
                            active: isActive,
                            ...(polygonProperties && polygonProperties[index] ? polygonProperties[index] : {}),
                        };
                        return {
                            type: 'Feature',
                            id: index,
                            geometry: {
                                type: 'Polygon',
                                coordinates: [coordinates],
                            },
                            properties,
                        };
                    }
                    return null;
                })
                .filter(Boolean);
            return { type: 'FeatureCollection', features: polygonFeatures };
        } else {
            return { type: 'FeatureCollection', features: [] };
        }
    }, [polygons, activeLotes, polygonProperties]);

    const bufferedLinesData = useMemo(() => {
        if (Array.isArray(bufferedLines)) {
            const features = getPolygonFeatures(bufferedLines);
            return { type: 'FeatureCollection', features };
        } else {
            return { type: 'FeatureCollection', features: [] };
        }
    }, [bufferedLines, getPolygonFeatures]);

    const bufferedIntersectionsData = useMemo(() => {
        if (Array.isArray(bufferedIntersections)) {
            const features = getPolygonFeatures(bufferedIntersections);
            return { type: 'FeatureCollection', features };
        } else {
            return { type: 'FeatureCollection', features: [] };
        }
    }, [bufferedIntersections, getPolygonFeatures]);

    const areasSuperpuestasData = useMemo(() => {
        if (Array.isArray(areasSuperpuestas)) {
            const processedAreas = areasSuperpuestas.map((item) => {
                if (item && item.geometry && item.geometry.coordinates) {
                    let geometry = item.geometry;
                    if (geometry.type === 'Polygon') {
                        geometry = {
                            ...geometry,
                            coordinates: geometry.coordinates.map((ring) =>
                                ring.map((coord) => [coord[1], coord[0]])
                            ),
                        };
                    } else if (geometry.type === 'MultiPolygon') {
                        geometry = {
                            ...geometry,
                            coordinates: geometry.coordinates.map((polygon) =>
                                polygon.map((ring) => ring.map((coord) => [coord[1], coord[0]]))
                            ),
                        };
                    }
                    return {
                        ...item,
                        geometry,
                    };
                } else if (Array.isArray(item)) {
                    const coordinates = item.map((coord) => [coord[1], coord[0]]);
                    return {
                        type: 'Feature',
                        geometry: {
                            type: 'Polygon',
                            coordinates: [coordinates],
                        },
                        properties: {},
                    };
                }
                return item;
            });
            const features = getPolygonFeatures(processedAreas);
            return { type: 'FeatureCollection', features };
        } else {
            return { type: 'FeatureCollection', features: [] };
        }
    }, [areasSuperpuestas, getPolygonFeatures]);

    const nonIntersectedAreasData = useMemo(() => {
        if (Array.isArray(nonIntersectedAreas)) {
            const processedAreas = nonIntersectedAreas.map((item) => {
                if (item && item.geometry && item.geometry.coordinates) {
                    let geometry = item.geometry;
                    if (geometry.type === 'Polygon') {
                        geometry = {
                            ...geometry,
                            coordinates: geometry.coordinates.map((ring) =>
                                ring.map((coord) => [coord[1], coord[0]])
                            ),
                        };
                    } else if (geometry.type === 'MultiPolygon') {
                        geometry = {
                            ...geometry,
                            coordinates: geometry.coordinates.map((polygon) =>
                                polygon.map((ring) => ring.map((coord) => [coord[1], coord[0]]))
                            ),
                        };
                    }
                    return {
                        ...item,
                        geometry,
                    };
                } else if (Array.isArray(item)) {
                    const coordinates = item.map((coord) => [coord[1], coord[0]]);
                    return {
                        type: 'Feature',
                        geometry: {
                            type: 'Polygon',
                            coordinates: [coordinates],
                        },
                        properties: {},
                    };
                }
                return item;
            });
            const features = getPolygonFeatures(processedAreas);
            return { type: 'FeatureCollection', features };
        } else {
            return { type: 'FeatureCollection', features: [] };
        }
    }, [nonIntersectedAreas, getPolygonFeatures]);

    const hasFitBoundsRef = useRef({
        points: false,
        lines: false,
        polygons: false,
        polygonsData: false,
        lineasNoFiltradas: false,
        image: false,
    });

    const fitBoundsToData = useCallback((dataFeatures) => {
        if (localMapRef.current && localMapRef.current.getMap()) {
            const map = localMapRef.current.getMap();
            const bounds = new mapboxgl.LngLatBounds();

            dataFeatures.forEach((feature) => {
                const coordinates = feature.geometry.coordinates;
                if (feature.geometry.type === 'Point') {
                    bounds.extend(coordinates);
                } else if (feature.geometry.type === 'LineString') {
                    coordinates.forEach((coord) => bounds.extend(coord));
                } else if (feature.geometry.type === 'Polygon') {
                    coordinates.forEach((ring) => {
                        ring.forEach((coord) => bounds.extend(coord));
                    });
                } else if (feature.geometry.type === 'MultiPolygon') {
                    coordinates.forEach((polygon) => {
                        polygon.forEach((ring) => {
                            ring.forEach((coord) => bounds.extend(coord));
                        });
                    });
                }
            });

            if (!bounds.isEmpty()) {
                map.fitBounds(bounds, { padding: 20 });
            }
        }
    }, []);

    useEffect(() => {
        if (mapLoaded && localMapRef.current && localMapRef.current.getMap()) {
            const map = localMapRef.current.getMap();
            const bounds = new mapboxgl.LngLatBounds();

            let shouldFitBounds = false;

            if (imageUrl && northWestCoords && southEastCoords && !hasFitBoundsRef.current.image) {
                bounds.extend([northWestCoords[0], northWestCoords[1]]);
                bounds.extend([southEastCoords[0], southEastCoords[1]]);
                hasFitBoundsRef.current.image = true;
                shouldFitBounds = true;
            }

            if (pointData.features.length > 0 && !hasFitBoundsRef.current.points) {
                pointData.features.forEach((feature) => {
                    const coordinates = feature.geometry.coordinates;
                    bounds.extend(coordinates);
                });
                hasFitBoundsRef.current.points = true;
                shouldFitBounds = true;
            }

            if (lineData.features.length > 0 && !hasFitBoundsRef.current.lines) {
                lineData.features.forEach((feature) => {
                    const coordinates = feature.geometry.coordinates;
                    coordinates.forEach((coord) => bounds.extend(coord));
                });
                hasFitBoundsRef.current.lines = true;
                shouldFitBounds = true;
            }

            if (lineasNoFiltradasData.features.length > 0 && !hasFitBoundsRef.current.lineasNoFiltradas) {
                lineasNoFiltradasData.features.forEach((feature) => {
                    const coordinates = feature.geometry.coordinates;
                    if (feature.geometry.type === 'LineString') {
                        coordinates.forEach((coord) => bounds.extend(coord));
                    } else if (feature.geometry.type === 'MultiLineString') {
                        coordinates.forEach((line) => {
                            line.forEach((coord) => bounds.extend(coord));
                        });
                    }
                });
                hasFitBoundsRef.current.lineasNoFiltradas = true;
                shouldFitBounds = true;
            }

            if (polygonDataMemo.features.length > 0 && !hasFitBoundsRef.current.polygons) {
                polygonDataMemo.features.forEach((feature) => {
                    const coordinates = feature.geometry.coordinates;
                    if (feature.geometry.type === 'Polygon') {
                        coordinates.forEach((ring) => {
                            ring.forEach((coord) => bounds.extend(coord));
                        });
                    } else if (feature.geometry.type === 'MultiPolygon') {
                        coordinates.forEach((polygon) => {
                            polygon.forEach((ring) => {
                                ring.forEach((coord) => bounds.extend(coord));
                            });
                        });
                    }
                });
                hasFitBoundsRef.current.polygons = true;
                shouldFitBounds = true;
            }

            if (polygonDataFeatures.features.length > 0 && !hasFitBoundsRef.current.polygonsData) {
                polygonDataFeatures.features.forEach((feature) => {
                    const coordinates = feature.geometry.coordinates;
                    if (feature.geometry.type === 'Polygon') {
                        coordinates.forEach((ring) => {
                            ring.forEach((coord) => bounds.extend(coord));
                        });
                    } else if (feature.geometry.type === 'MultiPolygon') {
                        coordinates.forEach((polygon) => {
                            polygon.forEach((ring) => {
                                ring.forEach((coord) => bounds.extend(coord));
                            });
                        });
                    }
                });
                hasFitBoundsRef.current.polygonsData = true;
                shouldFitBounds = true;
            }

            if (shouldFitBounds && !bounds.isEmpty()) {
                map.fitBounds(bounds, { padding: 20 });
            }
        }
    }, [
        mapLoaded,
        pointData.features.length,
        lineData.features.length,
        polygonDataMemo.features.length,
        polygonDataFeatures.features.length,
        lineasNoFiltradasData.features.length,
        imageUrl,
        northWestCoords,
        southEastCoords,
    ]);

    const handleCenterToPolygonsData = useCallback(() => {
        if (polygonDataFeatures.features.length > 0 && localMapRef.current) {
            fitBoundsToData(polygonDataFeatures.features);
        }
    }, [polygonDataFeatures.features, fitBoundsToData]);

    const handleCenterToAnalysis = useCallback(() => {
        if (localMapRef.current) {
            if (pointData.features.length > 0) {
                fitBoundsToData(pointData.features);
            } else if (lineData.features.length > 0) {
                fitBoundsToData(lineData.features);
            } else if (polygonDataMemo.features.length > 0) {
                fitBoundsToData(polygonDataMemo.features);
            } else if (imageUrl && northWestCoords && southEastCoords) {
                const map = localMapRef.current.getMap();
                const bounds = new mapboxgl.LngLatBounds();
                bounds.extend([northWestCoords[0], northWestCoords[1]]);
                bounds.extend([southEastCoords[0], southEastCoords[1]]);
                map.fitBounds(bounds, { padding: 20 });
            }
        }
    }, [
        localMapRef,
        pointData.features,
        lineData.features,
        polygonDataMemo.features,
        imageUrl,
        northWestCoords,
        southEastCoords,
        fitBoundsToData,
    ]);

    const onLineClickRef = useRef(onLineClick);
    const onLineHoverRef = useRef(onLineHover);
    const onLineMouseOutRef = useRef(onLineMouseOut);

    useEffect(() => {
        onLineClickRef.current = onLineClick;
    }, [onLineClick]);

    useEffect(() => {
        onLineHoverRef.current = onLineHover;
    }, [onLineHover]);

    useEffect(() => {
        onLineMouseOutRef.current = onLineMouseOut;
    }, [onLineMouseOut]);

    useEffect(() => {
        if (!mapLoaded || !localMapRef.current || !localMapRef.current.getMap()) return;
        const map = localMapRef.current.getMap();

        const handleClick = (e) => {
            const features = e.features;
            if (features && features.length > 0) {
                onLineClickRef.current(e);
            }
        };

        const handleMouseMove = (e) => {
            if (e.features && e.features.length > 0) {
                const feature = e.features[0];
                if (feature.layer.id === 'lines-layer') {
                    map.getCanvas().style.cursor = 'pointer';
                    map.setFeatureState(
                        { source: 'lines', id: feature.id },
                        { hover: true }
                    );
                    onLineHoverRef.current(feature);
                }
            }
        };

        const handleMouseLeave = () => {
            map.getCanvas().style.cursor = '';
            const features = map.querySourceFeatures('lines');
            features.forEach((feature) => {
                map.setFeatureState(
                    { source: 'lines', id: feature.id },
                    { hover: false }
                );
            });
            onLineMouseOutRef.current();
        };

        map.on('click', 'lines-layer', handleClick);
        map.on('mousemove', 'lines-layer', handleMouseMove);
        map.on('mouseleave', 'lines-layer', handleMouseLeave);

        return () => {
            map.off('click', 'lines-layer', handleClick);
            map.off('mousemove', 'lines-layer', handleMouseMove);
            map.off('mouseleave', 'lines-layer', handleMouseLeave);
        };
    }, [mapLoaded]);

    // ---------------------- LÓGICA AÑADIDA PARA SELECTED FILES ----------------------

    const [selectedFilesData, setSelectedFilesData] = useState([]);

    const getAnalysisTypeFromFileName = (fileName) => {
        if (fileName.includes('aplicaciones_aereas')) return 'Aplicaciones aéreas';
        if (fileName.includes('cosecha_mecanica')) return 'Cosecha mecánica';
        if (fileName.includes('conteo_de_palmas')) return 'Conteo de palmas';
        return 'Desconocido';
    };

    const isImageFile = (fileName) => {
        const ext = fileName.split('.').pop().toLowerCase();
        return ext === 'jpg' || ext === 'jpeg' || ext === 'tif' || ext === 'tiff' || ext === 'png';
    };

    useEffect(() => {
        const loadSelectedFiles = async () => {
            const results = [];

            for (let i = 0; i < (selectedFiles || []).length; i++) {
                const file = selectedFiles[i];
                const analysisType = getAnalysisTypeFromFileName(file.file_name || '');
                const date = file.updated || 'Fecha desconocida';
                const isFiltradas = file.file_name.includes('_filtradas');
                const isNoFiltradas = file.file_name.includes('_no_filtradas');

                if (isImageFile(file.file_name)) {
                    results.push({
                        type: 'image',
                        id: `selectedfile-image-${i}`,
                        imageUrl: file.signed_url,
                        coordinates: null,
                        analysisType,
                        date
                    });
                } else {
                    try {
                        const resp = await axios.get(file.signed_url);
                        let data = resp.data;

                        if (data.type === 'Feature') {
                            data = { type: 'FeatureCollection', features: [data] };
                        } else if (data.type !== 'FeatureCollection') {
                            data = { type:'FeatureCollection', features:[] };
                        }

                        data.features = data.features.map(f => ({
                            ...f,
                            properties: {
                                ...f.properties,
                                analysisType,
                                date,
                                filtradoEstado: isFiltradas ? 'filtradas' : (isNoFiltradas ? 'no_filtradas' : 'filtradas')
                            }
                        }));

                        results.push({
                            type: 'geojson',
                            id: `selectedfile-geojson-${i}`,
                            data,
                            analysisType,
                            date
                        });
                    } catch (error) {
                        console.error('Error al cargar selected file:', error);
                        results.push({
                            type: 'geojson',
                            id: `selectedfile-geojson-${i}`,
                            data: { type:'FeatureCollection', features:[] },
                            analysisType,
                            date
                        });
                    }
                }
            }

            setSelectedFilesData(results);
        };
        console.log("ESTE ES EL SELECTED FILES DESDE COMMON MAP: ", selectedFiles);
        loadSelectedFiles();
    }, [selectedFiles]);

    const [selectedFilesPopup, setSelectedFilesPopup] = useState(null);

    // Creamos una lista de capas interactivas para asegurar que el click en ellas genere e.features
    const interactiveLayers = useMemo(() => {
        const layers = [];
        selectedFilesData.forEach((fileData, index) => {
            if (fileData.type === 'geojson') {
                layers.push(`selectedfile-polygon-layer-${index}`);
                layers.push(`selectedfile-line-layer-${index}`);
                layers.push(`selectedfile-point-layer-${index}`);
            } else if (fileData.type === 'image' && fileData.coordinates) {
                layers.push(`${fileData.id}-layer`);
            }
        });
        return layers;
    }, [selectedFilesData]);

    useEffect(() => {
        if (!mapLoaded || !localMapRef.current || !localMapRef.current.getMap()) return;

        const map = localMapRef.current.getMap();

        const handleClick = (e) => {
            if (e.features && e.features.length > 0) {
                const feat = e.features[0];
                if (feat.layer.id && feat.properties && feat.properties.analysisType) {
                    const lngLat = e.lngLat;
                    const content = (
                        <div>
                            <strong>Análisis:</strong> {feat.properties.analysisType}<br/>
                            <strong>Fecha:</strong> {feat.properties.date}
                        </div>
                    );
                    setSelectedFilesPopup({ lngLat, content });
                }
            }
        };

        map.on('click', handleClick);

        return () => {
            if (map) {
                map.off('click', handleClick);
            }
        };
    }, [mapLoaded, selectedFilesData]);

    const pointLayerSelected = (id) => ({
        id: `selectedfile-point-layer-${id}`,
        type: 'circle',
        paint: {
            'circle-radius': 5,
            'circle-color': '#48ff00',
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff'
        },
        filter: ['==', '$type', 'Point']
    });

    // Aquí diferenciamos las líneas filtradas (rojo) de las no filtradas (gris)
    const lineLayerSelected = (id) => ({
        id: `selectedfile-line-layer-${id}`,
        type: 'line',
        paint: {
            'line-color': [
                'match',
                ['get', 'filtradoEstado'],
                'filtradas', '#ff0000',
                'no_filtradas', '#888',
                '#888'
            ],
            'line-width': 2
        },
        filter: ['==', '$type', 'LineString']
    });

    const polygonLayerSelected = (id) => ({
        id: `selectedfile-polygon-layer-${id}`,
        type: 'fill',
        paint: {
            'fill-color': '#ff0000',
            'fill-opacity': 0.4,
            'fill-outline-color': '#ff0000'
        },
        filter: ['==', '$type', 'Polygon']
    });

    return (
        <Box position="relative">
            <Map
                initialViewState={{
                    longitude: center[1],
                    latitude: center[0],
                    zoom: zoom,
                }}
                mapStyle={mapStyle}
                mapboxAccessToken="pk.eyJ1IjoiamRtYW4iLCJhIjoiY20xNGpkN2d0MHlsejJ4cTA1Z3lwdGNhMCJ9.O5UY8q02UPPoCCMrobQ0sA"
                style={{ height: '65vh', width: '100%' }}
                ref={localMapRef}
                projection="globe"
                preserveDrawingBuffer={true}
                onLoad={handleMapLoad}
            >
                <NavigationControl position="top-right" />

                {pointData.features.length > 0 && (
                    <Source id="points" type="geojson" data={pointData}>
                        <Layer
                            id="points-layer"
                            type="circle"
                            paint={{
                                'circle-radius': 2,
                                'circle-color': ['get', 'color'],
                            }}
                        />
                    </Source>
                )}

                {lineasNoFiltradasData.features.length > 0 && (
                    <Source id="lineas-no-filtradas" type="geojson" data={lineasNoFiltradasData}>
                        <Layer
                            id="lineas-no-filtradas-layer"
                            type="line"
                            paint={{
                                'line-color': ['get', 'color'],
                                'line-width': 2,
                            }}
                        />
                    </Source>
                )}

                {lineData.features.length > 0 && (
                    <Source id="lines" type="geojson" data={lineData}>
                        <Layer
                            id="lines-layer"
                            type="line"
                            paint={{
                                'line-color': [
                                    'case',
                                    ['boolean', ['feature-state', 'hover'], false],
                                    '#E0FFFF',
                                    ['get', 'color'],
                                ],
                                'line-width': 2,
                            }}
                        />
                    </Source>
                )}

                {polygonDataMemo.features.length > 0 && (
                    <Source id="polygons" type="geojson" data={polygonDataMemo}>
                        <Layer
                            id="polygons-layer"
                            type="fill"
                            paint={{
                                'fill-color': [
                                    'case',
                                    ['boolean', ['get', 'active'], false],
                                    'red',
                                    ['has', 'color'],
                                    ['get', 'color'],
                                    '#4ca100',
                                ],
                                'fill-opacity': 1,
                            }}
                        />
                    </Source>
                )}

                {polygonDataFeatures.features.length > 0 && (
                    <Source id="polygons-data" type="geojson" data={polygonDataFeatures}>
                        <Layer
                            id="polygons-data-layer"
                            type="fill"
                            paint={{
                                'fill-color': 'yellow',
                                'fill-opacity': 0.8,
                            }}
                        />
                    </Source>
                )}

                {bufferedLinesData.features.length > 0 && (
                    <Source id="buffered-lines" type="geojson" data={bufferedLinesData}>
                        <Layer
                            id="buffered-lines-layer"
                            type="fill"
                            paint={{
                                'fill-color': 'purple',
                                'fill-opacity': 0.5,
                            }}
                        />
                    </Source>
                )}

                {bufferedIntersectionsData.features.length > 0 && (
                    <Source id="buffered-intersections" type="geojson" data={bufferedIntersectionsData}>
                        <Layer
                            id="buffered-intersections-layer"
                            type="fill"
                            paint={{
                                'fill-color': 'blue',
                                'fill-opacity': 1,
                            }}
                        />
                    </Source>
                )}

                {areasSuperpuestasData.features.length > 0 && (
                    <Source id="areas-superpuestas" type="geojson" data={areasSuperpuestasData}>
                        <Layer
                            id="areas-superpuestas-layer"
                            type="fill"
                            paint={{
                                'fill-color': 'red',
                                'fill-opacity': 1,
                            }}
                        />
                    </Source>
                )}

                {nonIntersectedAreasData.features.length > 0 && (
                    <Source id="non-intersected-areas" type="geojson" data={nonIntersectedAreasData}>
                        <Layer
                            id="non-intersected-areas-layer"
                            type="fill"
                            paint={{
                                'fill-color': 'yellow',
                                'fill-opacity': 1,
                            }}
                        />
                    </Source>
                )}

                {imageUrl && northWestCoords && southEastCoords && (
                    <Source
                        id="image-overlay"
                        type="image"
                        url={imageUrl}
                        coordinates={[
                            [northWestCoords[0], northWestCoords[1]],
                            [southEastCoords[0], northWestCoords[1]],
                            [southEastCoords[0], southEastCoords[1]],
                            [northWestCoords[0], southEastCoords[1]],
                        ]}
                    >
                        <Layer
                            id="image-overlay-layer"
                            type="raster"
                            paint={{
                                'raster-opacity': 1,
                            }}
                        />
                    </Source>
                )}

                {/* Capas de selectedFiles */}
                {selectedFilesData.map((fileData, index) => {
                    if (fileData.type === 'geojson') {
                        return (
                            <Source key={fileData.id} id={fileData.id} type="geojson" data={fileData.data}>
                                <Layer {...polygonLayerSelected(index)} />
                                <Layer {...lineLayerSelected(index)} />
                                <Layer {...pointLayerSelected(index)} />
                            </Source>
                        );
                    } else if (fileData.type === 'image' && fileData.coordinates) {
                        return (
                            <Source
                                key={fileData.id}
                                id={fileData.id}
                                type="image"
                                url={fileData.imageUrl}
                                coordinates={fileData.coordinates}
                            >
                                <Layer
                                    id={`${fileData.id}-layer`}
                                    type="raster"
                                    paint={{ 'raster-opacity': 1 }}
                                />
                            </Source>
                        );
                    }
                    return null;
                })}

                {popupInfo && (
                    <Popup
                        longitude={popupInfo.lngLat.lng}
                        latitude={popupInfo.lngLat.lat}
                        onClose={() => setPopupInfo(null)}
                        closeOnClick={false}
                    >
                        {popupInfo.content}
                    </Popup>
                )}

                {selectedFilesPopup && (
                    <Popup
                        longitude={selectedFilesPopup.lngLat.lng}
                        latitude={selectedFilesPopup.lngLat.lat}
                        onClose={() => setSelectedFilesPopup(null)}
                        closeOnClick={false}
                    >
                        {selectedFilesPopup.content}
                    </Popup>
                )}
            </Map>

            <Box position="absolute" top={10} left={10} zIndex={1000}>
                <Select
                    value={selectedStyle}
                    onChange={(e) => {
                        const selected = e.target.value;
                        setSelectedStyle(selected);
                        setMapStyle(mapStyles[selected]);
                    }}
                    sx={{
                        backgroundColor: 'white',
                        color: 'black',
                        '& .MuiSelect-select': {
                            backgroundColor: 'white',
                        },
                        minWidth: 140,
                    }}
                >
                    {Object.keys(mapStyles).map((styleName) => (
                        <MenuItem key={styleName} value={styleName}>
                            {styleName}
                        </MenuItem>
                    ))}
                </Select>
            </Box>

            <Box position="absolute" top={10} right={10} zIndex={1000}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <StyledIconButton onClick={handleCenterToPolygonsData}>
                        <HomeIcon fontSize="small" />
                    </StyledIconButton>
                    <StyledIconButton onClick={handleCenterToAnalysis}>
                        <MyLocationIcon fontSize="small" />
                    </StyledIconButton>
                </Box>
            </Box>
        </Box>
    );
};

export default CommonMap;
