// ToolbarComponent.jsx

import React, { useState } from 'react';
import {
    Button,
    Tooltip,
    Input,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField
} from '@mui/material';
import { toast } from 'react-toastify';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import AnalysisIcon from '@mui/icons-material/BarChart';
import ExcelIcon from '@mui/icons-material/InsertDriveFile';
import ShapefileIcon from '@mui/icons-material/Map';
import TemplateIcon from '@mui/icons-material/Download';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import AnalysisControls from '../../views/Dashboard/AnalysisControls/AnalysisControls';
import FloatingPanel from '../FloatingPanel/FloatingPanel';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import analysisUtils from "../../utils/analysisConfig";
import { manejarSubidaZip } from "../../utils/fileHandler";
import { captureMapImage } from "../../analysis/AnalysisMapping/MappingGeneral";
import {
    ToolbarContainer,
    ButtonGroup,
    StyledButton,
    IconButtonStyled,
    ButtonSection,
    TooltipStyled,
} from './ToolbarComponentStyle';
import Loader from '../Loader/Loader';

const ToolbarComponent = ({
                              selectedAnalysisType,
                              handleAnalysisTypeChange,
                              manejarSubidaArchivo,
                              uploadedCsvFileName,
                              uploadedZipFileName,
                              execBash,
                              activarEdicionInteractiva,
                              setActivarEdicionInteractiva,
                              isKMLFile,
                              execBashEnabled,
                              isSidebarOpen,
                              polygonsData = [],
                              highlightedLote,
                              activeLotes,
                              searchTerm,
                              setSearchTerm,
                              onHoverLote,
                              onLeaveLote,
                              onSelectLote,
                              clearAllLotes,
                              openFilterDialog,
                              processingFinished,
                              handleSendDashboardData,
                              analysisOptions,
                              setIsKMLFile,
                              setOpenSnackbar,
                              setSelectedZipFile,
                              setUploadedZipFileName,
                              mapRef,
                              setImgLaflet,
                              setIsGeneratingReport,
                              isGeneratingReport
                          }) => {

    const [openCommentModal, setOpenCommentModal] = useState(false);
    const [comment, setComment] = useState('');

    const handleGeneratePdf = async () => {
        setOpenCommentModal(true);
    };

    const handleCloseModal = () => {
        setOpenCommentModal(false);
        setComment('');
    };

    const handleSubmitComment = () => {
        setOpenCommentModal(false);
        handleSendPdfWithComment();
    };

    const handleSendPdfWithComment = async () => {
        try {
            setIsGeneratingReport(true);
            if (mapRef.current) {
                const imgData = await captureMapImage(mapRef);
                setImgLaflet(imgData);
                await handleSendDashboardData(imgData, comment);
            }
        } catch (error) {
            console.error("Error generando el PDF:", error);
            setIsGeneratingReport(false);
        }
    };

    // Función para manejar la subida de Plantilla con validación
    const handleUploadTemplate = (event) => {
        const file = event.target.files[0];
        if (file) {
            const allowedExtensions = /(\.csv|\.xls|\.xlsx)$/i;
            if (!allowedExtensions.exec(file.name)) {
                toast.warning('Solo se permiten archivos CSV o Excel (.csv, .xls, .xlsx)');
                event.target.value = ''; // Limpiar el input
                return;
            }
            manejarSubidaArchivo(event);
        }
    };

    const handleUploadShp = (event) => {
        const file = event.target.files[0];
        if (file) {
            const allowedExtension = /\.zip$/i;
            if (!allowedExtension.exec(file.name)) {
                toast.warning('Solo se permiten archivos ZIP (.zip)');
                event.target.value = '';
                return;
            }
            manejarSubidaZip(event, setSelectedZipFile, setUploadedZipFileName, setOpenSnackbar, setIsKMLFile);
        }
    };

    return (
        <ToolbarContainer isSidebarOpen={isSidebarOpen}>
            <ButtonSection>
                <ButtonGroup>
                    <AnalysisControls
                        selectedAnalysisType={selectedAnalysisType}
                        handleAnalysisTypeChange={handleAnalysisTypeChange}
                        analysisOptions={analysisOptions}
                    />
                    <Tooltip
                        title={
                            !selectedAnalysisType
                                ? 'Selecciona un análisis antes de comenzar'
                                : uploadedCsvFileName || 'No se ha seleccionado ningún archivo'
                        }
                    >
                        <StyledButton
                            variant="contained"
                            component="label"
                            startIcon={<ExcelIcon />}
                            disabled={!selectedAnalysisType || selectedAnalysisType === 'CONTEO_PALMA'}
                            className={'subir-csv'}
                        >
                            Subir Plantilla
                            <Input
                                type="file"
                                hidden
                                onChange={handleUploadTemplate}
                                accept=".csv,.xls,.xlsx"
                            />
                        </StyledButton>
                    </Tooltip>
                    <Tooltip
                        title={
                            !selectedAnalysisType
                                ? 'Selecciona un análisis antes de comenzar'
                                : uploadedZipFileName || 'No se ha seleccionado ningún archivo'
                        }
                    >
                        <StyledButton
                            variant="contained"
                            component="label"
                            startIcon={<ShapefileIcon />}
                            className={'subir-zip'}
                            disabled={
                                !selectedAnalysisType || selectedAnalysisType === 'COSECHA_MECANICA'
                            }
                        >
                            Subir shp
                            <Input
                                type="file"
                                hidden
                                onChange={handleUploadShp}
                                accept=".zip"
                            />
                        </StyledButton>
                    </Tooltip>

                    <FloatingPanel
                        polygonsData={polygonsData}
                        highlightedLote={highlightedLote}
                        activeLotes={activeLotes}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        onHoverLote={onHoverLote}
                        onLeaveLote={onLeaveLote}
                        onSelectLote={onSelectLote}
                        clearAllLotes={clearAllLotes}
                    />
                </ButtonGroup>
            </ButtonSection>

            <ButtonSection>
                <ButtonGroup>
                    <TooltipStyled title="Configurar filtros">
                        <StyledButton
                            variant="contained"
                            color="primary"
                            onClick={openFilterDialog}
                            disabled={!processingFinished}
                        >
                            Generar Mapas
                        </StyledButton>
                    </TooltipStyled>

                    <Tooltip
                        title={
                            !selectedAnalysisType
                                ? 'Selecciona un análisis antes de comenzar'
                                : 'Selecciona tu CSV'
                        }
                    >
                        <StyledButton
                            variant="contained"
                            className={'descargar-plantilla'}
                            startIcon={<TemplateIcon />}
                            disabled={!selectedAnalysisType}
                            href={selectedAnalysisType ? analysisUtils[selectedAnalysisType]?.templatePath : '#'}
                            download
                        >
                            Descargar plantilla
                        </StyledButton>
                    </Tooltip>
                    <StyledButton
                        variant="contained"
                        onClick={execBash}
                        startIcon={<AnalysisIcon />}
                        disabled={!execBashEnabled}
                        className={'realizar-analisis'}
                    >
                        Realizar Análisis
                    </StyledButton>
                    <Tooltip
                        title={
                            activarEdicionInteractiva
                                ? 'Desactivar Edición Interactiva'
                                : 'Activar Edición Interactiva'
                        }
                    >
                        <IconButtonStyled
                            onClick={() => setActivarEdicionInteractiva(!activarEdicionInteractiva)}
                            disabled={!isKMLFile}
                            active={activarEdicionInteractiva}
                        >
                            <AutoModeIcon />
                        </IconButtonStyled>
                    </Tooltip>
                    <Tooltip title="Generar informe">
                        <IconButtonStyled onClick={handleGeneratePdf}>
                            <PictureAsPdfIcon />
                        </IconButtonStyled>
                    </Tooltip>

                </ButtonGroup>
            </ButtonSection>
            {isGeneratingReport && <Loader />}

            <Dialog
                open={openCommentModal}
                onClose={handleCloseModal}
                maxWidth="lg"
                fullWidth={true}
            >
                <DialogTitle>Agregar Comentario al Reporte</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="comment"
                        label="Comentario"
                        type="text"
                        fullWidth
                        multiline
                        rows={8}
                        variant="outlined"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        InputProps={{
                            style: {
                                fontSize: '1rem',
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: '1rem',
                            },
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="secondary" variant="outlined">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmitComment} color="primary" variant="contained">
                        Generar Reporte
                    </Button>
                </DialogActions>
            </Dialog>
        </ToolbarContainer>
    );

};

export default React.memo(ToolbarComponent);
