// SidebarStyle.js

import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const SidebarWrapper = styled(Box)({
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'row',
    zIndex: 1000,
    height: '100vh',
});

export const LogoTab = styled(Box)({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '210px',
    height: '80px',
    backgroundColor: '#fff',
    borderBottomRightRadius: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1100,
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
});

export const LogoImage = styled('img')({
    maxWidth: '160px',
    maxHeight: '80px',
});

export const SidebarContainer = styled(Box)(({ theme }) => ({
    width: '75px',
    height: '100vh',
    backgroundColor: '#fff',
    color: '#333',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 1000,
    overflowY: 'auto',
    paddingTop: '0',
    boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
    position: 'relative',
    marginTop: '0',
}));

export const MenuItemsContainer = styled(Box)({
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '80px',
});

export const MenuItemContainer = styled(Box)(({ active }) => ({
    width: '100%',
    padding: '15px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.3s, transform 0.3s, border-left 0.3s',
    color: active ? '#1d2d50' : '#333',
    backgroundColor: active ? '#d1d8e0' : 'transparent',
    '&:hover': {
        backgroundColor: '#f0f0f0',
        color: '#1d2d50',
    },
    position: 'relative',
}));

export const MenuIconContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.5em',
});

export const TooltipContainer = styled(Box)(({ top, left }) => ({
    position: 'absolute',
    backgroundColor: '#333',
    color: '#fff',
    padding: '5px 10px',
    borderRadius: '5px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
    whiteSpace: 'nowrap',
    zIndex: 1100,
    fontSize: '0.9em',
    pointerEvents: 'none',
    top: top,
    left: left,
}));

export const ModalContent = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '350px', // Aseguramos que el ancho coincida con el ContentContainer
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    '@keyframes rotate': {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(360deg)',
        },
    },
});

export const HelpButton = styled('button')({
    marginTop: '10px',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    boxShadow: '0 2px 5px rgba(0,0,0,.3)',
    zIndex: 1050,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '28px',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#0b7dda',
    },
});

export const FullscreenButton = styled('button')({
    marginTop: '10px',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    backgroundColor: '#28a745',
    color: 'white',
    border: 'none',
    boxShadow: '0 2px 5px rgba(0,0,0,.3)',
    zIndex: 1050,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '28px',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#218838',
    },
});

export const SubMenuContainer = styled(Box)({
    position: 'absolute',
    width: '350px', // Aseguramos que el ancho coincida con el ContentContainer
    backgroundColor: '#fff',
    boxShadow: '2px 2px 5px rgba(0,0,0,0.1)',
    zIndex: 1000,
    overflowY: 'auto',
    maxHeight: '80vh',
    borderRadius: '4px',
});
