import React from 'react';
import { Box, Typography } from '@mui/material';

import { VideoBackground, Overlay } from './AdvertisingStyle';

import backgroundVideo from "../../assets/login/background.mp4";

const Advertising = ({ isSidebarOpen }) => {
    const sidebarWidth = 75; // Ancho del sidebar en píxeles

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: `${sidebarWidth}px`,
                width: `calc(100% - ${sidebarWidth}px)`,
                height: '100vh',
                transition: 'left 0.5s ease, width 0.5s ease',
                overflow: 'hidden',
            }}
        >
            <VideoBackground autoPlay loop muted>
                <source src={backgroundVideo} type="video/mp4" />
                Tu navegador no soporta el video en HTML5.
            </VideoBackground>
            <Overlay>
                <Box>
                    <Typography variant="h3" sx={{ mb: 2, fontWeight: 'bold', fontSize: '3rem' }}>
                        INGEOMAP
                    </Typography>
                    <Typography variant="h5" sx={{ fontSize: '1.5rem' }}>
                        Una solución rápida y amigable para el mapeo, análisis y estadísticas INTEGRAL de tus datos agrícolas
                    </Typography>
                </Box>
            </Overlay>
        </Box>
    );
};

export default Advertising;
