// src/utils/reportService.js

import axios from 'axios';
import { API_BASE_URL } from './config';
import moment from 'moment';

const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};

export const sendDashboardData = async (imgLaflet, indicadores, idUsuario, logo, labelsAndColors, comment, tipoMapeo) => {
    try {
        console.log("LEAFLET: ", imgLaflet);
        console.log("INDICADORES: ", indicadores);
        console.log("ID USUARIO: ", idUsuario);
        console.log("LOGO: ", logo);
        console.log("LABELS AND COLORS: ", labelsAndColors);
        console.log("COMMENT: ", comment);
        let logoBase64 = logo;
        if (logo.startsWith("blob:")) {
            const response = await fetch(logo);
            const blob = await response.blob();
            logoBase64 = await convertBlobToBase64(blob);
        }

        const watermarkResponse = await fetch('/logo.png');
        const watermarkBlob = await watermarkResponse.blob();
        const watermarkBase64 = await convertBlobToBase64(watermarkBlob);

        // Obtener la zona horaria del usuario
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Usar moment para obtener la fecha y hora local formateada
        const now = moment();
        const formattedDateTime = now.format('dddd, D [de] MMMM [de] YYYY, h:mm A'); // Ejemplo: "viernes, 1 de enero de 2021, 3:30 PM"
        const formattedFileDateTime = now.format('YYYYMMDD_HHmmss'); // Ejemplo: "20210101_153000"

        const payload = {
            imgData: imgLaflet,
            indicadores: indicadores,
            tipoReporte: indicadores.analisis,
            usuarioId: idUsuario,
            logo: logoBase64,
            watermark: watermarkBase64,
            tipoMapeo: tipoMapeo,
            labelsAndColors: {
                title: labelsAndColors.title,
                labels: labelsAndColors.labels
            },
            comment: comment,
            currentDateTime: formattedDateTime, // Fecha y hora formateada para el contenido del PDF
            fileDateTime: formattedFileDateTime, // Fecha y hora formateada para el nombre del archivo
            timeZone: timeZone // Opcional: enviar la zona horaria si deseas almacenarla o usarla en el backend
        };

        const response = await axios.post(`${API_BASE_URL}reporteria/mapeo`, payload, {
            responseType: 'blob'
        });

        if (response.status === 200) {
            const fileName = `${indicadores.analisis}_${formattedFileDateTime}.pdf`;

            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            window.URL.revokeObjectURL(url);
            link.remove(); // Limpiar el DOM
        } else {
            console.error("Error al generar el reporte");
        }
    } catch (error) {
        console.error("Error al enviar los datos del dashboard:", error);
    }
};
