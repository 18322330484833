import { throttle } from 'lodash';
import ReactDOM from 'react-dom';


export const captureMapImage = (mapRef) => {
    return new Promise((resolve, reject) => {
        if (mapRef.current) {
            const map = mapRef.current;
            const canvas = map.getCanvas();

            try {
                const imgData = canvas.toDataURL('image/png');
                resolve(imgData);
            } catch (error) {
                console.error("Error capturing map image:", error);
                reject(error);
            }
        } else {
            reject(new Error("Map reference not available"));
        }
    });
};


export const handleZoom = (localMapRef, setMapZoom) => {
    if (localMapRef.current) {
        const map = localMapRef.current;
        const zoomLevel = map.getZoom();
        if (zoomLevel !== undefined) {
            setMapZoom(zoomLevel);
        }
    }
};

export const handleMoveEnd = (localMapRef, setMapCenter) => {
    if (localMapRef.current) {
        const map = localMapRef.current;
        const center = map.getCenter();
        if (center) {
            ReactDOM.unstable_batchedUpdates(() => {
                setMapCenter([center.lat, center.lng]);
            });
        }
    }
};

// Creación de la función throttle fuera de un hook o componente React.
export const throttledHandleMoveEnd = throttle(handleMoveEnd, 100);

export const fitBoundsIfValid = (map, bounds) => {
    if (bounds.isValid() && map) {
        map.fitBounds(bounds);
    }
};
